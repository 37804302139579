import useForms from "@metronome/api/useForms";
import useStepInstance from "@metronome/api/useStepInstance";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@metronome/components/Accordion";
import type React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useAttachments from "@metronome/api/useAttachments";
import useEntityHistories from "@metronome/api/useHistory";
import { PaginatedList } from "@metronome/components/PaginatedList";
import { defaultPagination } from "@metronome/constants/pagination";
import useSetCurrentOrganization from "@metronome/hooks/useSetCurrentOrganization";
import type { IPagination } from "@metronome/types/PaginatedResponse";

import { Attachment } from "@metronome/components/Attachment";
import FallbackView from "@metronome/components/FallbackView";
import { IndeterminateProgressBar } from "@metronome/components/IndeterminateProgressBar";
import { Markup } from "interweave";
import { AttachmentsTab } from "../AttachmentsTab";
import { AgendaActivitiesTemplate } from "./AgendaActivitiesTemplate";
import { StepInstanceTemplate } from "./StepInstanceTemplate";
import styles from "./stepInstancePage.module.scss";
import FormsTab from "./tabs/FormsTab";

import { HistoryTemplate } from "@metronome/features/History/HistoryTemplate";
import { NotesTab } from "@metronome/features/Notes";
import { Resources } from "../NodesReferences";
import { StepTimeline } from "../StepTimeline";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { StepState } from "../StepState";
import { FeaturedMetadata } from "../FeaturedMetadata";

type Context = "step-instances";

const defaultOpenedAccordion = [
	"featured-metadata",
	"objective",
	"groundRules",
	"businessDimensions",
	"references",
	"nodeReferences",
	"attachments",
	"notes",
	"activities",
	"forms",
];

const StepInstancePage: React.FC<{
	stepId: string;
	streamId: string;
}> = ({ stepId }) => {
	const intl = useIntl();
	const [pagination, setPagination] = useState<IPagination>({
		...defaultPagination,
		page: Number.parseInt(location.hash.substring(1), 10) || 1,
	});

	const { data: stepInstance, isLoading } = useStepInstance(stepId ?? "");
	const { data: forms } = useForms(stepInstance?.id);
	const { data: histories } = useEntityHistories(
		"step-instances",
		{
			page: pagination.page,
			pageSize: pagination.pageSize,
		},
		stepInstance?.id,
	);

	useSetCurrentOrganization(stepInstance?.processInstance.organizationId);

	const { data: attachments } = useAttachments<Context>(
		"step-instances",
		stepId,
	);

	if (!stepInstance) {
		return <FallbackView />;
	}

	return (
		<div className="flex flex-col gap-4 grow relative">
			{isLoading && (
				<div className={styles.overlayLoader}>
					<IndeterminateProgressBar />
				</div>
			)}
			<StepInstanceTemplate stepInstance={stepInstance} isSideOpen={true}>
				<div className="uppercase text-xs font-semibold">
					<FormattedMessage id="PLANNING" />
				</div>
				<StepState
					schedule={stepInstance.schedule}
					resolution={stepInstance.resolution}
					stepInstanceId={stepInstance.id}
					processInstanceId={stepInstance.processInstance.id}
					processState={stepInstance.processInstance.state}
					type={stepInstance.type}
				/>
				<Resources stepInstanceId={stepInstance.id} />
				<Accordion
					defaultValue={defaultOpenedAccordion}
					className="flex flex-col gap-4 pb-12"
					type="multiple"
				>
					{/* FeaturedMetadata below act as a conditional AccordionItem - only rendereing if necessary */}
					<FeaturedMetadata
						contextId={stepInstance.id}
						context="step-instances"
						businessDimension={stepInstance.businessDimension}
					/>
					<AccordionItem value="timeline">
						<AccordionTrigger>
							<div className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180">
								<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
								<span className="font-bold text-base">
									<FormattedMessage id="PLANNED" />
								</span>
							</div>
						</AccordionTrigger>
						<AccordionContent>
							<StepTimeline
								type={stepInstance.type}
								schedule={stepInstance.schedule}
								resolution={stepInstance.resolution}
							/>
						</AccordionContent>
					</AccordionItem>
					{!!stepInstance.objective && (
						<AccordionItem value="objective">
							<AccordionTrigger asChild>
								<div className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180">
									<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.OBJECTIVE",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div>
									<Markup
										className={styles.breakSpaces}
										content={stepInstance.objective}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{!!stepInstance.groundRules && (
						<AccordionItem value="groundRules">
							<AccordionTrigger asChild>
								<div className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180">
									<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.GROUND_RULES",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div>
									<Markup
										className={styles.breakSpaces}
										content={stepInstance.groundRules}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}

					{!!attachments?.preloaded.length && (
						<AccordionItem value="references">
							<AccordionTrigger asChild>
								<div className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180">
									<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.REFERENCES",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div className="m-2 flex flex-col gap-2">
									{attachments?.preloaded?.map((attachment) => (
										<Attachment
											key={attachment.id}
											context="step-instances"
											contextId={stepId}
											attachment={attachment}
											preloaded
										/>
									))}
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{!!(
						attachments?.additional?.length || attachments?.required?.length
					) && (
						<AccordionItem value="attachments">
							<AccordionTrigger asChild>
								<div className={styles.trigger}>
									<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.TABS.ATTACHMENTS",
										})}
										{` (${
											attachments?.additional?.length +
											attachments?.required?.length
										})`}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div className={styles.content}>
									<AttachmentsTab
										context="step-instances"
										contextId={stepInstance.id}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{/* activities */}
					{!!stepInstance.agenda?.length && (
						<AccordionItem value="activities">
							<AccordionTrigger asChild>
								<div className={styles.trigger}>
									<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.TABS.AGENDA_ACTIVITIES",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div>
									<AgendaActivitiesTemplate
										agenda={stepInstance.agenda}
										stepInstanceId={stepId}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{/* forms */}
					{!!forms?.length && (
						<AccordionItem value="forms">
							<AccordionTrigger asChild>
								<div className={styles.trigger}>
									<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
									<span className="font-bold text-base">
										{intl.formatMessage({ id: "STEP_INSTANCE.TABS.FORMS" })}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent asChild>
								<div>
									<FormsTab
										stepInstanceId={stepInstance.id}
										resolution={stepInstance.resolution}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{/* notes */}
					<AccordionItem value="notes">
						<AccordionTrigger asChild>
							<div className={styles.trigger}>
								<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
								<span className="font-bold text-base">
									{intl.formatMessage({ id: "STEP_INSTANCE.TABS.NOTES" })}
								</span>
							</div>
						</AccordionTrigger>
						<AccordionContent asChild>
							<div>
								<NotesTab context={"step-instances"} contextId={stepId} />
							</div>
						</AccordionContent>
					</AccordionItem>
					{/* histories */}
					{!!histories?.results?.length && (
						<AccordionItem value="histories">
							<AccordionTrigger asChild>
								<div className={styles.trigger}>
									<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "TABS.HISTORY",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div>
									<PaginatedList
										pagination={pagination}
										totalPages={histories?.totalPages}
										onPageChange={(newPagination: IPagination) =>
											setPagination(newPagination)
										}
									>
										<HistoryTemplate
											stepInstanceType={stepInstance.type}
											histories={histories.results}
										/>
									</PaginatedList>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
				</Accordion>
			</StepInstanceTemplate>
		</div>
	);
};
export default StepInstancePage;
