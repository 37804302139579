import { CalendarIcon } from "@radix-ui/react-icons";
import { useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button } from "@metronome/components/ui/button";
import { Calendar } from "@metronome/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { TimePickerInput } from "@metronome/components/ui/timePickerInput";
import { cn } from "@metronome/utils";
import { formatObjectDate } from "@metronome/utils/dateHelpers";

type DateTimePickerProps = {
	initialDate?: Date;
	onValidate?: (date: Date | undefined) => void;
};

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
	initialDate,
	onValidate,
}) => {
	const intl = useIntl();
	const [date, setDate] = useState<Date | undefined>(initialDate);
	const [open, setOpen] = useState(false);
	const onSelect = useCallback(
		(newDate: Date | undefined) => {
			const initialHours = date?.getHours() ?? 0;
			const initalMinutes = date?.getMinutes() ?? 0;
			const updatedDate = newDate?.setHours(initialHours, initalMinutes);
			if (updatedDate) {
				const dateAsDate = new Date(updatedDate);
				if (dateAsDate) setDate(dateAsDate);
			}
		},
		[date],
	);

	const onSaveHours = useCallback(() => {
		if (initialDate?.getTime() !== date?.getTime()) onValidate?.(date);
		setOpen(false);
	}, [date, onValidate, initialDate]);

	const displayDate = initialDate
		? formatObjectDate(initialDate, intl)
		: undefined;

	return (
		<Popover modal onOpenChange={setOpen} open={open}>
			<PopoverTrigger asChild>
				<Button
					variant={"outline"}
					onClick={() => setOpen(!open)}
					className={cn(
						"justify-start text-left font-normal",
						!date && "text-muted-foreground",
					)}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{initialDate ? (
						<span>{`${displayDate?.date} ${displayDate?.time}`}</span>
					) : (
						<span>
							<FormattedMessage id="PICK_A_DATE" />
						</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={date}
					defaultMonth={initialDate}
					onSelect={onSelect}
				/>
				<div className="p-3 border-t border-border">
					<TimePickerInput onSave={onSaveHours} setDate={setDate} date={date} />
				</div>
			</PopoverContent>
		</Popover>
	);
};
