import React, { useCallback } from "react";

import type { useUpsertMetadataValue } from "@metronome/api/useMetadataDefinitions";
import { ComboBoxMultiSelect } from "@metronome/components/ComboBoxMultiSelect";
import type { Metadata, MetadataValue } from "@metronome/types/Metadata";

type MultiSelectMetadataProps = {
	definition: Metadata["definition"];
	metadataValue: MetadataValue;
	nodeId?: string;
	mutateMetadata: ReturnType<typeof useUpsertMetadataValue>["mutate"];
};

export const MultiSelectMetadata: React.FC<MultiSelectMetadataProps> = ({
	definition,
	metadataValue,
	nodeId,
	mutateMetadata,
}) => {
	const { value } = metadataValue;
	const initialValue = value as string[];
	const [val, setVal] = React.useState<string[]>(initialValue); // ugly, will need a refactor

	const [isDirty, setIsDirty] = React.useState(false);

	const onMenuToggle = useCallback(() => {
		if (isDirty && nodeId) {
			mutateMetadata({
				definitionId: definition.id,
				nodeId: nodeId,
				metadataValues: [
					metadataValue?.id
						? {
								id: metadataValue.id,
								value: val,
							}
						: {
								value: val,
							},
				],
			});
		}
	}, [nodeId, isDirty, metadataValue?.id, definition.id, mutateMetadata, val]);

	if (definition.enum)
		return (
			<ComboBoxMultiSelect
				items={definition.enum.map((p) => ({
					label: p,
					value: p,
				}))}
				value={val}
				setValue={(value: string[]) => {
					setIsDirty(true);
					setVal(value);
				}}
				onClose={onMenuToggle}
			/>
		);

	return null;
};
