import type { IParty } from "@metronome/types/Party";
import type { IUser } from "@metronome/types/User";
import { useIntl } from "react-intl";

export function getUserUri(
	user: { profilePictureUri?: string } | undefined,
): string | undefined {
	return !user?.profilePictureUri
		? undefined
		: `${window.VITE_API_URL || import.meta.env.VITE_API_URL}/${
				user.profilePictureUri
			}`;
}

type MaybeUserOrParty = IParty | IUser | undefined;

export function useName(user: MaybeUserOrParty): string {
	const { locale } = useIntl();
	return `${user?.lastName?.toLocaleUpperCase(locale) ?? ""} ${
		user?.firstName ?? ""
	}`;
}
