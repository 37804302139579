import { SchedulerError } from "@metronome/components/SchedulerError";
import { Epg, Layout, useEpg, type Zone } from "@nessprim/planby-pro";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@metronome/components/ui/select";
import { FormattedDateTimeRange, FormattedMessage } from "react-intl";
import {
	TimelineWithNav,
	WeekMonthTimelineWithNav,
} from "./planby/CustomTimeline";
import type {
	Area,
	ChannelWithOmittedUuid,
	FetchZone,
	Mode,
	ProgramWithOmittedUuid,
} from "@nessprim/planby-pro/dist/Epg/helpers";
import {
	durationInDays,
	getDayWidth,
	getDefaultTimeRange,
	getStartDate,
	globalStyles,
} from "@metronome/utils/planby";
import { useCallback, useMemo, useState } from "react";
import {
	COMPACT_SIZE,
	planbyLightTheme,
} from "@metronome/constants/planbyTheme";
import { endOfDay } from "date-fns/endOfDay";
import { format } from "date-fns/format";
import SwitchButton from "@metronome/components/Switch";
import { ErrorBoundary } from "@sentry/react";
import {
	add,
	addDays,
	differenceInCalendarDays,
	type Duration,
	isWithinInterval,
	sub,
} from "date-fns";
import type { ChannelWithPosition } from "@nessprim/planby-pro/dist/Epg/helpers/types";
import { CustomItem } from "./planby/CustomItem";
import { Button } from "@metronome/components/ui/button";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	CaretLeftIcon,
	CaretRightIcon,
} from "@radix-ui/react-icons";
import { useRect } from "@metronome/hooks/useRect";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@metronome/components/ui/tooltip";
type PrevNextItems = {
	nextScrollRight: number;
	nextScrollLeft: number;
	itemsOnTheRight: number;
	itemOnTheRightTitle: string;
	itemsOnTheLeft: number;
	itemOnTheLeftTitle: string;
	itemOnTheRightSince: string;
	itemOnTheLeftSince: string;
};
type ProgramByChannel = {
	uuid: string | undefined;
	items: Array<{ left: number; width: number; title: string; since: string }>;
}[];
function usePrevNextItems(
	programByChannel: ProgramByChannel,
	channelUuid: string,
	scrollX: number,
	timelineWidth: number,
): PrevNextItems {
	const itemsInChannel =
		programByChannel.find((p) => p.uuid === channelUuid)?.items ?? [];

	const nextRightIndex = itemsInChannel.findIndex(
		(v) => v.left > scrollX + timelineWidth,
	);
	const nextLeftIndex = itemsInChannel.findIndex(
		(v) => v.left + v.width < scrollX,
	);
	const nextScrollRight =
		nextRightIndex >= 0
			? itemsInChannel[nextRightIndex]?.left -
				timelineWidth +
				itemsInChannel[nextRightIndex]?.width
			: -1;
	const nextScrollLeft =
		nextLeftIndex >= 0 ? itemsInChannel[nextLeftIndex]?.left : -1;

	const itemsOnTheRight =
		nextRightIndex >= 0 ? itemsInChannel.length - nextRightIndex : 0;
	const itemsOnTheLeft =
		nextLeftIndex >= 0 ? itemsInChannel.length - nextLeftIndex : 0;

	const itemOnTheRightTitle = itemsInChannel[nextRightIndex]?.title;
	const itemOnTheLeftTitle = itemsInChannel[nextLeftIndex]?.title;
	const itemOnTheLeftSince = itemsInChannel[nextLeftIndex]?.since;
	const itemOnTheRightSince = itemsInChannel[nextRightIndex]?.since;

	return {
		nextScrollRight,
		nextScrollLeft,
		itemsOnTheRight,
		itemsOnTheLeft,
		itemOnTheRightTitle,
		itemOnTheLeftTitle,
		itemOnTheLeftSince,
		itemOnTheRightSince,
	};
}

type SelectRangeProps = {
	rangeMode: Mode["type"];
	availableRanges: Array<Mode["type"]>;
	setRangeMode: (type: Mode["type"]) => void;
};
const SelectRange = ({
	rangeMode,
	setRangeMode,
	availableRanges,
}: SelectRangeProps) => {
	return (
		<Select
			value={rangeMode as string}
			onValueChange={(value: Mode["type"]) => {
				setRangeMode(value);
			}}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select range" />
			</SelectTrigger>
			<SelectContent>
				{availableRanges.map((range) => (
					<SelectItem key={range} value={range}>
						<FormattedMessage id={range.toUpperCase()} />
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

type LoadMoreDaysFn = (
	timeDirection: "past" | "future",
	range: Mode["type"],
) => void;

type TimeRangeNavigationProps = {
	range: Mode["type"];
	plannedStart: string;
	plannedEnd: string;
	onLoadMoreDays: LoadMoreDaysFn;
};
const TimeRangeNavigation: React.FC<TimeRangeNavigationProps> = ({
	range,
	plannedStart,
	plannedEnd,
	onLoadMoreDays,
}) => {
	return (
		<div className="mx-auto flex items-center gap-2">
			<Button
				variant="ghost"
				size="icon-sm"
				onClick={() => onLoadMoreDays("past", range)}
			>
				<ArrowLeftIcon />
			</Button>
			<FormattedDateTimeRange
				from={new Date(plannedStart)}
				to={new Date(plannedEnd)}
			/>
			<Button
				variant="ghost"
				size="icon-sm"
				onClick={() => onLoadMoreDays("future", range)}
			>
				<ArrowRightIcon />
			</Button>
		</div>
	);
};

function getEnoughDaysToFillSelectedMode(range: Mode["type"], days: number) {
	if (range === "day") return Math.abs(days) < 1 ? 1 - Math.abs(days) : 0;
	if (range === "week") return Math.abs(days) < 7 ? 7 - Math.abs(days) : 0;
	if (range === "month") return Math.abs(days) < 180 ? 180 - Math.abs(days) : 0;
	return 0;
}

function getInitialPlannedEnd(start: string, end: string): string {
	const range = getDefaultTimeRange(start, end);
	const days = differenceInCalendarDays(start, end);
	const daysToAdd = getEnoughDaysToFillSelectedMode(range, days);
	return addDays(end, daysToAdd).toISOString();
}

function getDurationToApply(mode: Mode["type"], amount?: number): Duration {
	switch (mode) {
		case "month":
			return { weeks: amount ?? 1 };
		case "week":
			return { days: amount ?? 1 };
		case "day":
			return { hours: amount ?? 1 };
	}
}

type StepsTimelineProps = {
	initialPlannedStart: string;
	initialPlannedEnd: string;
	epg: ProgramWithOmittedUuid[];
	channels: ChannelWithOmittedUuid[];
	areas?: Area[];
	CustomChannelItem?: React.ComponentType<{
		channel: ChannelWithPosition;
		children: React.ReactNode;
		displayMode: "compact" | "expanded";
	}>;
	selectedMetadataDefs?: string[];
	fetchZone?: FetchZone;
};

export const StepsTimeline: React.FC<StepsTimelineProps> = ({
	initialPlannedStart,
	initialPlannedEnd,
	epg,
	areas,
	channels = [],
	CustomChannelItem,
	fetchZone,
}) => {
	const [planRect, planRef] = useRect();

	// state
	const [plannedStart, setPlannedStart] = useState(initialPlannedStart);
	const [plannedEnd, setPlannedEnd] = useState(
		getInitialPlannedEnd(initialPlannedStart, initialPlannedEnd),
	);
	const displayMode = "compact";

	const [showSchedule, setShowSchedule] = useState(false);

	// getters
	const defaultRange = getDefaultTimeRange(plannedStart, plannedEnd);
	const numberOfDays = durationInDays(plannedStart, plannedEnd);
	const timelineStartDate = getStartDate(plannedStart);

	const [rangeMode, setRangeMode] = useState<Mode["type"]>(defaultRange);

	const onRangeModeChange = useCallback(
		(type: Mode["type"]) => {
			const days = differenceInCalendarDays(plannedStart, plannedEnd);
			const daysToAdd = getEnoughDaysToFillSelectedMode(type, days);
			if (daysToAdd > 0) {
				setPlannedEnd((prev) => addDays(prev, daysToAdd).toISOString());
			}

			setRangeMode(type);
		},
		[plannedEnd, plannedStart],
	);

	const endDate = rangeMode === "day" ? plannedEnd : endOfDay(plannedEnd);
	const endDateFormatted = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

	const onFetchZone = useCallback((_zone: Zone) => {
		// todo
	}, []);

	const dayWidth = getDayWidth(rangeMode, numberOfDays);
	const sidebarWidth = 180;
	// planBy hooks
	const {
		getEpgProps,
		getLayoutProps,
		scrollX,
		onScrollToNow,
		onScrollRight,
		onScrollLeft,
	} = useEpg({
		epg,
		areas,
		channels,
		startDate: timelineStartDate,
		endDate: endDateFormatted,
		mode: { type: rangeMode, style: "modern" },
		sidebarWidth,
		dayWidth,
		itemHeight: COMPACT_SIZE,
		isSidebar: !!CustomChannelItem,
		isTimeline: true,
		isLine: true,
		isCurrentTime: true,
		theme: planbyLightTheme,
		overlap: {
			enabled: true,
			mode: "stack",
		},
		globalStyles,
		fetchZone: fetchZone
			? fetchZone
			: {
					enabled: false,
					timeSlots: 1,
					onFetchZone,
				},
		timezone: {
			enabled: true,
			mode: "utc",
			zone: "Europe/Paris",
		},
	});

	const onLoadMoreDays: LoadMoreDaysFn = useCallback(
		(timeDirection, mode) => {
			const durationToSub = getDurationToApply(mode);
			if (timeDirection === "past") {
				const nextPlannedStart = sub(plannedStart, durationToSub);
				const nextPlannedEnd = sub(plannedEnd, durationToSub);
				setPlannedStart(nextPlannedStart.toISOString());
				setPlannedEnd(nextPlannedEnd.toISOString());
			} else if (timeDirection === "future") {
				const nextPlannedEnd = add(plannedEnd, durationToSub);
				const nextPlannedStart = add(plannedStart, durationToSub);
				setPlannedEnd(nextPlannedEnd.toISOString());
				setPlannedStart(nextPlannedStart.toISOString());
			}
		},
		[plannedEnd, plannedStart],
	);

	if (!(new Date(plannedStart) <= new Date(plannedEnd))) {
		return <SchedulerError startDateAfterEndDate />;
	}

	const scrollToToday = useCallback(() => {
		const isTodayOnTheTimeline = isWithinInterval(new Date(), {
			start: plannedStart,
			end: plannedEnd,
		});
		if (isTodayOnTheTimeline) return onScrollToNow();
		const duration = getDurationToApply(
			rangeMode,
			rangeMode === "day" ? 24 : 7,
		);
		setPlannedStart(sub(new Date(), duration).toISOString());
		setPlannedEnd(add(new Date(), duration).toISOString());
		onScrollToNow();
	}, [onScrollToNow, plannedEnd, plannedStart, rangeMode]);

	type ScrollToItemFn = (
		scrollFn: (x: number) => void,
		scrollTo: number,
		since: string,
	) => boolean;
	const scrollToItem: ScrollToItemFn = useCallback(
		(scrollFn, scrollTo, since) => {
			const isTargetOnTheTimeline = isWithinInterval(since, {
				start: plannedStart,
				end: plannedEnd,
			});
			if (isTargetOnTheTimeline) {
				scrollFn(scrollTo);
				return true;
			}
			const duration = getDurationToApply(
				rangeMode,
				rangeMode === "day" ? 24 : 7,
			);
			setPlannedStart(new Date(since).toISOString());
			setPlannedEnd(add(since, duration).toISOString());
			return isTargetOnTheTimeline;
		},
		[plannedStart, plannedEnd, rangeMode],
	);

	const { programs } = getLayoutProps();
	const programByChannel = useMemo(() => {
		const itemsLeftPosition: number[] = [];
		const data = channels.map((c) => {
			return {
				uuid: c.uuid,
				items: [
					...new Map(
						programs
							.filter((p) => p.data.channelUuid === c.uuid)
							.map((p) => {
								itemsLeftPosition.push(p.position.left);
								return [
									p.position.left,
									{
										left: p.position.left,
										width: p.position.width,
										title: p.data.title,
										since: p.data.since,
									},
								];
							}),
					).values(),
				],
				itemsLeftPosition,
			};
		});
		return { data, itemsLeftPosition };
	}, [channels, programs]);

	const { itemsLeftPosition, data } = programByChannel;
	// biome-ignore lint/correctness/noUnusedVariables: <explanation>
	const minScrollX = Math.min(...itemsLeftPosition);
	const timelineWidth = planRect?.width ? planRect.width - sidebarWidth : 0;

	return (
		<>
			<div className="flex gap-2 items-center p-2">
				<SwitchButton
					checked={showSchedule}
					onCheckedChange={setShowSchedule}
				/>
				<span>
					<FormattedMessage id="SHOW_SCHEDULE" />
				</span>

				<TimeRangeNavigation
					plannedStart={plannedStart}
					plannedEnd={plannedEnd}
					onLoadMoreDays={onLoadMoreDays}
					range={rangeMode}
				/>
				<SelectRange
					rangeMode={rangeMode}
					setRangeMode={onRangeModeChange}
					availableRanges={["day", "week", "month"]}
				/>
				<Button variant="secondary" onClick={() => scrollToToday()}>
					<FormattedMessage id="TODAY" />
				</Button>
			</div>
			<ErrorBoundary fallback={<SchedulerError />}>
				<div ref={planRef}>
					<Epg {...getEpgProps()}>
						<TooltipProvider delayDuration={0}>
							<Layout
								{...getLayoutProps()}
								renderProgram={({ program, hourWidth, ...rest }) => {
									return (
										<CustomItem
											key={program.data.id}
											program={program}
											hourWidth={hourWidth}
											showStepType={false}
											showSchedule={showSchedule}
											{...rest}
										/>
									);
								}}
								{...(CustomChannelItem
									? {
											renderChannel: ({ channel, ...rest }) => {
												const {
													nextScrollRight,
													itemsOnTheRight,
													nextScrollLeft,
													itemsOnTheLeft,
													itemOnTheRightTitle,
													itemOnTheLeftTitle,
													itemOnTheLeftSince,
													itemOnTheRightSince,
												} = usePrevNextItems(
													data,
													channel.uuid,
													scrollX,
													timelineWidth,
												);

												const top = channel.position.top;
												return (
													<CustomChannelItem
														key={channel.uuid}
														channel={channel}
														displayMode={displayMode}
														{...rest}
													>
														{!!itemsOnTheRight && (
															<Tooltip>
																<TooltipTrigger asChild>
																	<Button
																		onClick={() => {
																			const isTargetWithinInterval =
																				scrollToItem(
																					onScrollRight,
																					nextScrollRight - scrollX,
																					itemOnTheRightSince,
																				);
																			if (!isTargetWithinInterval) {
																				onScrollRight(
																					nextScrollRight - scrollX,
																				);
																			}
																		}}
																		style={{
																			top: `${top}px`,
																			right: `-${timelineWidth - 10}px`,
																			transform: "translateY(calc(20px - 50%))",
																		}}
																		variant="outline"
																		className="absolute"
																		size="icon-sm"
																	>
																		<CaretRightIcon />
																	</Button>
																</TooltipTrigger>
																<TooltipContent align="center" side="left">
																	{itemOnTheRightTitle}
																</TooltipContent>
															</Tooltip>
														)}
														{!!itemsOnTheLeft && (
															<Tooltip>
																<TooltipTrigger asChild>
																	<Button
																		onClick={() => {
																			const isTargetWithinInterval =
																				scrollToItem(
																					onScrollLeft,
																					scrollX - nextScrollLeft,
																					itemOnTheLeftSince,
																				);
																			if (!isTargetWithinInterval) {
																				onScrollLeft(scrollX - nextScrollLeft);
																			}
																		}}
																		style={{
																			top: `${top}px`,
																			left: `${sidebarWidth}px`,
																			transform: "translateY(calc(20px - 50%))",
																		}}
																		className={"absolute"}
																		variant="outline"
																		size="icon-sm"
																	>
																		<CaretLeftIcon />
																	</Button>
																</TooltipTrigger>
																<TooltipContent align="center" side="right">
																	{itemOnTheLeftTitle}
																</TooltipContent>
															</Tooltip>
														)}
													</CustomChannelItem>
												);
											},
										}
									: {})}
								renderTimeline={
									rangeMode === "day"
										? (props) => (
												<TimelineWithNav {...props} timelineDividers={2} />
											)
										: (props) => <WeekMonthTimelineWithNav {...props} />
								}
							/>
						</TooltipProvider>
					</Epg>
				</div>
			</ErrorBoundary>
		</>
	);
};
