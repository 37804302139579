import {
	keepPreviousData,
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { apiDelete, apiGetWithSchema, apiPost } from "./api";
import { ResourcesArray } from "@metronome/types/Resources";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { ICursorPaginatedResults } from "@metronome/types/PaginatedResponse";
import type { IModelReference } from "@metronome/types/ModelReference";

const stepResourceKeys = {
	step: (workspaceId: string, stepInstanceId: string) => [
		workspaceId,
		"step-instance",
		stepInstanceId,
		"resources",
	],
};
export function useStepResources(stepInstanceId: string) {
	const workspaceId = useWorkspaceId();
	return useQuery({
		queryKey: stepResourceKeys.step(workspaceId, stepInstanceId),
		queryFn: () =>
			apiGetWithSchema<ResourcesArray>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/resources`,
				ResourcesArray,
			),
	});
}

export function useStepAvailableResources(
	stepInstanceId: string,
	nodeReferenceDefinitionId: string,
	search: string,
) {
	const workspaceId = useWorkspaceId();
	return useInfiniteQuery({
		queryKey: [
			workspaceId,
			"step-instance",
			stepInstanceId,
			"available-resources",
			nodeReferenceDefinitionId,
			search,
		],
		queryFn: ({ pageParam }) =>
			apiGetWithSchema<ICursorPaginatedResults<IModelReference>>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/available-resources`,
				ICursorPaginatedResults,
				{
					params: { nodeReferenceDefinitionId, cursor: pageParam, search },
				},
			),
		initialPageParam: "",
		getNextPageParam: (lastPage) => lastPage.nextCursor,
		placeholderData: keepPreviousData,
	});
}

type ResourcePayload = {
	nodeReferenceDefinitionId: string;
	nodeId: string;
	from?: string;
	to?: string;
};

export function useDeleteStepResources(stepInstanceId: string) {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ nodeReferenceDefinitionId, nodeId }: ResourcePayload) =>
			apiDelete(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/resources`,
				{
					nodeReferenceDefinitionId,
					nodeId,
				},
			),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: stepResourceKeys.step(workspaceId, stepInstanceId),
			});
		},
	});
}

export function useCreateStepResources(stepInstanceId: string) {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({
			nodeReferenceDefinitionId,
			nodeId,
			from,
			to,
		}: ResourcePayload) =>
			apiPost(`ws/${workspaceId}/step-instances/${stepInstanceId}/resources`, {
				nodeReferenceDefinitionId,
				nodeId,
				from,
				to,
			}),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: stepResourceKeys.step(workspaceId, stepInstanceId),
			});
		},
	});
}
