import * as duration from "duration-fns";
import type {
	Mode,
	ProgramWithOmittedUuid,
} from "@nessprim/planby-pro/dist/Epg/helpers";
import { format, roundToNearestHours } from "date-fns";
import type { ISchedule } from "@metronome/types/StepInstance";
import type { IStage } from "@metronome/types/Gate";
import type { Channel, Program } from "@nessprim/planby-pro";
import { useMemo } from "react";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";

export const getStartDate = (startDate: string): string => {
	let date: undefined | Date;
	try {
		date = new Date(startDate);
	} catch {
		throw new Error("Could not parse the date");
	}
	return format(
		roundToNearestHours(date, { roundingMethod: "floor" }),
		"yyyy-MM-dd'T'HH:mm:ss",
	);
};

export const getDefaultTimeRange = (
	first: string,
	last: string,
): Mode["type"] => {
	const durationBetween = duration.between(first, last);
	const normalizedDuration = duration.normalize(durationBetween);
	const numberofDays = duration.toDays(normalizedDuration);

	if (numberofDays > 3) {
		return "week";
	}
	return "day";
};

export const getDayWidth = (
	mode: Mode["type"],
	numberOfDays: number,
): number => {
	if (mode === "day" && numberOfDays >= 1) return 1800 * numberOfDays;
	if (mode === "week") return 200 * numberOfDays;
	if (mode === "month") return 25 * numberOfDays;
	return 1800;
};

export const durationInDays = (dateRight: string, dateLeft: string) => {
	const durationRaw = duration.between(dateRight, dateLeft);
	return duration.toDays(durationRaw);
};

export function calcTillDate(schedule: ISchedule): Date {
	const {
		resolvedAt,
		plannedDuration,
		estimatedDuration,
		plannedAt,
		scheduleLowerBand,
	} = schedule;

	if (resolvedAt) return new Date(resolvedAt);
	const durationParsed = duration.parse(plannedDuration ?? estimatedDuration);
	if (duration.isZero(durationParsed)) {
		return new Date(schedule.scheduleUpperBand);
	}
	const tillDate = duration.apply(
		plannedAt ?? scheduleLowerBand,
		durationParsed,
	);
	return tillDate;
}

export function getMinutesBetweenDates(
	dateRight: duration.DateInput,
	dateLeft: duration.DateInput,
): number {
	const d = duration.between(dateRight, dateLeft);
	let toMinutes: number;
	try {
		toMinutes = duration.toUnit(d, duration.UNITS[5]);
	} catch {
		return 0;
	}
	return toMinutes;
}

/**
 * Doing this on the FE for now but ought to have his own api route to avoid the code below
 * @param gates
 * @returns
 */
export const useBuildStepsEpgFromStages = (
	gates: IStage[],
	workspaceId: string,
	processInstance: IProcessInstance, // should be a getter fn ? getChannelUUid
): { epg: Program[]; channels: Channel[]; error?: string } => {
	let error = "";
	if (!gates || gates?.length === 0) {
		error = "Gates array is empty";
	}
	const epg: ProgramWithOmittedUuid[] = [];

	useMemo(
		() =>
			gates.forEach(({ steps }) =>
				steps.forEach((step) =>
					step.stepInstances.forEach((instance) => {
						const { scheduleLowerBand, plannedAt } = instance.schedule;
						const { type } = instance;

						epg.push({
							id: instance.id,
							channelUuid: processInstance.id,
							image: "",
							since: format(
								plannedAt ?? scheduleLowerBand,
								"yyyy-MM-dd'T'HH:mm:ss",
							),
							till: format(
								calcTillDate(instance.schedule),
								"yyyy-MM-dd'T'HH:mm:ss",
							),
							schedule: instance.schedule,
							title: `${step.name} / ${instance.businessDimension.name}`,
							type,
							workspaceId,
							processId: processInstance.id,
							labels: instance.businessDimension.labels,
							resolution: instance.resolution,
						});
					}),
				),
			),
		[gates, workspaceId, processInstance.id],
	);

	const channels = useMemo(
		() => [
			{
				uuid: processInstance.id,
				logo: "",
				title: processInstance.name,
				progress: processInstance.progress,
				schedule: processInstance.schedule,
				resolution: processInstance.resolution,
				workspaceId: workspaceId,
			},
		],
		[processInstance, workspaceId],
	);

	return { epg, channels, error };
};

export const globalStyles = `
.planby {
  padding: 0px !important;
}
.planby {
  .planby-corner-box {
    z-index: 50;
  }
  .planby-program-content {
    background: none;
  }
    .planby-timeline-wrapper {
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	z-index: 49;
  }
  #planby-wrapper {
    border-radius: 0px;
  }
  .planby-content {
    --off-white-color: white;
    background-image: linear-gradient(#eee 1.1px, transparent 1.1px),
      linear-gradient(to right, #eee 1.1px, var(--off-white-color) 1.1px);
    background-size: 22px 22px;
    background-color: rgb(241, 235, 226);
    background-repeat: repeat;
  }
    .planby-program {
      z-index: 1;
    }
    .planby-channels {
      z-index: 20;
    }
}
`;
