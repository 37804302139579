import { z } from "zod";
import {
	IBusinessDimensionNode,
	ILightBusinessDimensionNode,
} from "./BusinessDimension";
import { IModelReference, IRankedModelReference } from "./ModelReference";
import { IParty } from "./Party";
import { IProcessInstance } from "./ProcessInstance";
import { EResolution } from "./Resolution.js";
import { UserDoer } from "./Doer";

export const IAgenda = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string().optional(),
	optional: z.boolean(),
	duration: z.string(),
	completed: z.boolean(),
	completedOn: z.union([z.date(), z.string()]).optional(),
	completedBy: IParty.optional(),
});
export type IAgenda = z.infer<typeof IAgenda>;

export const EStepInstanceType = z.enum(["event", "task", "hook", "milestone"]);
export type EStepInstanceType = z.infer<typeof EStepInstanceType>;

export const IStepInstanceView = z.object({
	party: IParty,
	viewAt: z.number(),
});

export type IStepInstanceView = z.infer<typeof IStepInstanceView>;

export const ISchedule = z.object({
	// below are from the planning
	scheduleLowerBand: z.string(),
	scheduleUpperBand: z.string(),
	estimatedDuration: z.string(),
	atRiskRange: z.string(),
	// below are the regulator's job
	plannedAt: z.string().optional(),
	resolvedAt: z.string().optional(),
	plannedDuration: z.string().optional(),
	scheduledByRegulator: z.boolean().optional(),
});
export type ISchedule = z.infer<typeof ISchedule>;

const IProcessInstanceListItem = IProcessInstance.pick({
	id: true,
	name: true,
	organizationId: true,
	processStream: true,
	processTemplate: true,
	state: true,
});

export const StepInstanceCompletion = z.object({
	performedBy: UserDoer,

	performedAt: z.number(),
});
export type StepInstanceCompletion = z.infer<typeof StepInstanceCompletion>;

export const IStepInstance = z.object({
	id: z.string().min(1),
	resolution: EResolution,
	stage: IRankedModelReference,
	step: IRankedModelReference,
	stepTemplate: IModelReference,
	schedule: ISchedule,
	objective: z.string(),
	groundRules: z.string(),
	processInstance: IProcessInstanceListItem,
	agenda: z.array(IAgenda),
	isFlagged: z.boolean(),
	showTime: z.boolean(),
	dueDateModified: z.boolean(),
	notLaterThanModified: z.boolean(),
	businessDimension: IBusinessDimensionNode,
	isBlocked: z.boolean(),
	views: z.array(IStepInstanceView).optional(),
	type: EStepInstanceType,
	completion: StepInstanceCompletion.optional(),
});

export type IStepInstance = z.infer<typeof IStepInstance>;

export const IStepRequirements = z.object({
	name: z.enum(["forms", "attachments", "previousStepInstances"]),
	fulfilled: z.boolean(),
	ref: z.array(
		z.object({
			id: z.string().min(1),
			name: z.string(),
		}),
	),
});
export type IStepRequirements = z.infer<typeof IStepRequirements>;

export const IStepTemplateFilter = z.object({
	name: z.string().min(1),
	ids: z.array(z.string()),
});
export type IStepTemplateFilter = z.infer<typeof IStepTemplateFilter>;

export const IFilters = z.object({
	stepTypes: EStepInstanceType.optional(),
	labels: z.array(IModelReference),
	processStreams: z.array(IModelReference),
	stepTemplates: z.array(IStepTemplateFilter),
	nodeTypes: z.array(IModelReference),
});
export type IFilters = z.infer<typeof IFilters>;

export type StepInstanceContext = "activities";

export const IStepInstanceLight = IStepInstance.pick({
	id: true,
	type: true,
	schedule: true,
	isFlagged: true,
	isBlocked: true,
	businessDimension: true,
	resolution: true,
});

export type IStepInstanceLight = z.infer<typeof IStepInstanceLight>;

const ClusterGroup = z.object({
	maxDateTime: z.string(),
	minDateTime: z.string(),
	resource: ILightBusinessDimensionNode,
	stepInstances: z.array(
		IStepInstanceLight.extend({
			name: z.string(),
			processInstanceId: z.string(),
		}),
	),
	stepInstancesCount: z.number(),
});

export const ClusteredStepInstances = z.object({
	stepTemplateId: z.string(),
	stepTemplateName: z.string(),
	groups: z.array(ClusterGroup),
	groupsCount: z.number(),
	maxDateTime: z.string(),
	minDateTime: z.string(),
});

export type ClusteredStepInstances = z.infer<typeof ClusteredStepInstances>;
