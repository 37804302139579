import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type React from "react";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import styles from "./mainMenu.module.scss";
import {
	ActivityLogIcon,
	BarChartIcon,
	DashboardIcon,
} from "@radix-ui/react-icons";
import { Link, useMatchRoute } from "@tanstack/react-router";

type InnerMenuItemProps = {
	children: React.ReactNode;
	title: string;
};

const InnerMenuItem: React.FC<InnerMenuItemProps> = ({ children, title }) => (
	<div className={styles.menu_item}>
		<div className={styles.icon}>{children}</div>
		<div className={styles.menu_title}>{title}</div>
	</div>
);

export const AsideMenuMain: React.FC = () => {
	const intl = useIntl();
	const workspaceId = useWorkspaceId();

	const isOnboardingRoute = window.location.pathname === "/onboarding";

	const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

	if (isOnboardingRoute) {
		return null;
	}

	const matchRoute = useMatchRoute();
	const singleStreamMatched = matchRoute({
		to: "/$workspaceId/stream/$streamId",
		fuzzy: true,
	});
	return (
		<aside className={isMobile ? styles.container_mobile : styles.container}>
			<div className={styles.main} data-testid="main-menu">
				<Link
					to="/$workspaceId/streams"
					params={{ workspaceId }}
					className={`${styles.menu_link} ${singleStreamMatched ? styles.active_item : ""}`}
					activeProps={{ className: styles.active_item }}
					activeOptions={{
						exact: false,
					}}
					data-testid="MENU.PROCESS"
				>
					<InnerMenuItem title={intl.formatMessage({ id: "MENU.PROCESS" })}>
						<DashboardIcon />
					</InnerMenuItem>
				</Link>
				<Link
					to="/$workspaceId/activities"
					params={{ workspaceId }}
					className={styles.menu_link}
					activeProps={{ className: styles.active_item }}
					data-testid="MENU.ACTIVITIES"
				>
					<InnerMenuItem title={intl.formatMessage({ id: "MENU.ACTIVITIES" })}>
						<ActivityLogIcon />
					</InnerMenuItem>
				</Link>
				<Link
					to="/$workspaceId/workload"
					params={{ workspaceId }}
					search={{
						method: "count",
					}}
					className={styles.menu_link}
					activeProps={{ className: styles.active_item }}
					data-testid="MENU.DASHBOARD"
					preload="intent"
				>
					<InnerMenuItem title={intl.formatMessage({ id: "DASHBOARD" })}>
						<BarChartIcon />
					</InnerMenuItem>
				</Link>
			</div>
		</aside>
	);
};
