import { useGetNode } from "@metronome/api/useNodes";
import HoverPopover from "@metronome/components/HoverPopover";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { Badge } from "@metronome/components/ui/badge";
import type React from "react";

export const Node: React.FC<{
	id: string;
	children: React.ReactNode;
	topButton?: React.ReactNode;
}> = ({ id, children, topButton }) => {
	const { data, isLoading } = useGetNode(id);
	if (isLoading) return <LoadingMetronome />;
	if (!data) return null;
	const { name, labels, tree, nodeType, createdBy } = data;
	return (
		<HoverPopover header={children}>
			<div className="flex flex-col">
				<div className="flex items-center justify-between">
					<span className="font-bold">{name}</span>
					{topButton}
				</div>
				<span className="text-slate-600">{tree.name}</span>
				<span className="text-slate-600">{nodeType?.name}</span>
				<span className="text-slate-600">{createdBy?.name}</span>
				{labels.map((label) => (
					<Badge key={label} variant="secondary" className="me-2">
						{label}
					</Badge>
				))}
			</div>
		</HoverPopover>
	);
};
