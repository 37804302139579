import { z } from "zod";
import { IDoer } from "./Doer";
import { IModelReference } from "./ModelReference";

const EBusinessDimensionType = z.enum([
	"flights",
	"products",
	"geography",
	"account",
	"factory",
	"warehouse",
	"supplier",
	"project",
	"generic",
	"reporting",
	"barges",
	"orders",
]);

type EBusinessDimensionType = z.infer<typeof EBusinessDimensionType>;

const ENodeType = z.enum([
	"undefined",
	"root",
	"clustering",
	"sequencing",
	"transaction",
	"asset",
]);

type ENodeType = z.infer<typeof ENodeType>;

export const IBusinessDimension = z.object({
	id: z.string().min(1),
	name: z.string(),
	description: z.string().optional(),
	type: EBusinessDimensionType,
});

export type IBusinessDimension = z.infer<typeof IBusinessDimension>;

export const IMetadataValue = z.object({
	id: z.string().min(1),
	definition: IModelReference,
	type: z.string(),
	value: z.union([z.array(z.string()), z.string(), z.number()]).optional(),
	createdBy: IDoer,
});

export type IMetadataValue = z.infer<typeof IMetadataValue>;

export const IBusinessDimensionNode = z.object({
	id: z.string().min(1),
	name: z.string(),
	description: z.string().optional(),
	tree: IBusinessDimension,
	type: ENodeType,
	labels: z.array(z.string()),
	nodeType: IModelReference.optional(),
	createdBy: IDoer.optional(),
});

export type IBusinessDimensionNode = z.infer<typeof IBusinessDimensionNode>;

// should be a pick
export const ILightBusinessDimensionNode = IBusinessDimensionNode.pick({
	id: true,
	name: true,
	description: true,
	tree: true,
	type: true,
});

export type ILightBusinessDimensionNode = z.infer<
	typeof ILightBusinessDimensionNode
>;

export const INodeReference = z.object({
	referencedNode: ILightBusinessDimensionNode,
	referencedSpecId: z.string(),
});
export type INodeReference = z.infer<typeof INodeReference>;
