import {
	EEventResolution,
	EMilestoneResolution,
	EResolution,
} from "@metronome/types/Resolution";
import type { IStepInstance } from "@metronome/types/StepInstance";

export const getResolutionOptions = (
	type: IStepInstance["type"],
	StepResolution: EResolution,
): EResolution[] => {
	switch (type) {
		case "task":
			return EResolution.options.filter(
				(resolution) => resolution !== StepResolution,
			);
		case "event":
			return EEventResolution.options.filter(
				(resolution) => resolution !== StepResolution,
			);
		case "milestone":
			return EMilestoneResolution.options.filter(
				(resolution) => resolution !== StepResolution,
			);
		default:
			return EResolution.options.filter(
				(resolution) => resolution !== StepResolution,
			);
	}
};

export const getIsResolutionDoneOrCancel = (
	resolution: IStepInstance["resolution"],
): boolean =>
	resolution === EResolution.enum.done ||
	resolution === EResolution.enum.cancelled;
