import useSelf from "@metronome/api/useSelf";
import type React from "react";
import { useMediaQuery } from "react-responsive";
import { HeaderUserMenu } from "@metronome/components/HeaderUserMenu";
import { Notifications } from "@metronome/features/Notification";
import { useIsMobile } from "@metronome/hooks/useResponsive";
import type { IUser } from "@metronome/types/User";
import type { IWorkspace } from "@metronome/types/Workspace";

import { useWebsocketContext } from "@metronome/context/WebSocketContext";
import { isDev, isLocalhost } from "@metronome/utils/env";
// @ts-ignore
import Logo from "../../assets/logotype.svg?react";
import SwitchOrganization from "../SwitchOrganization";
import { DefaultTitle } from "./DefaultTitle";
import styles from "./header.module.scss";
import { Link, useCanGoBack, useRouter } from "@tanstack/react-router";
import { ChevronLeftIcon, HomeIcon } from "@radix-ui/react-icons";
import { Button } from "../ui/button";

const LogoWithLink = (): JSX.Element => (
	<Link to="/">
		<div className={styles.logo_container}>
			<Logo />
		</div>
	</Link>
);

const MobileMenu: React.FC = () => {
	const { data, isSuccess } = useSelf();
	const router = useRouter();
	const canGoBack = useCanGoBack();
	if (isSuccess) {
		return (
			<>
				{canGoBack ? (
					<Button size="icon-sm" onClick={() => router.history.back()}>
						<ChevronLeftIcon className="text-white scale-125" />
					</Button>
				) : (
					<Link
						to="/$workspaceId/streams"
						params={{ workspaceId: data.defaultWorkspaceId }}
					>
						<HomeIcon className="text-white scale-125" />
					</Link>
				)}
			</>
		);
	}
	return null;
};

type Props = {
	user?: IUser;
	workspaces?: IWorkspace[];
};

const WebSocketNotifier = () => {
	const [_subscribe, _unsubscribe, state] = useWebsocketContext();
	return (
		<div
			className={`absolute z-10 right-0 bottom-0 ms-auto h-2 w-2 rounded-full ${
				state === 1 ? "bg-green-600" : "bg-red-600"
			}`}
		/>
	);
};

export const Header: React.FC<Props> = ({ user, workspaces }) => {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
	const isMobile = useIsMobile();

	return (
		<div className={styles.container}>
			<div className="w-full px-6 flex items-stretch justify-between">
				<div className="flex items-center justify-between grow">
					{/* begin::Navbar */}

					{isTabletOrMobile ? <MobileMenu /> : <LogoWithLink />}
					<div className={styles.title}>
						<DefaultTitle />
					</div>

					<div className="flex items-center shrink gap-4 relative">
						{!!(isDev || isLocalhost) && <WebSocketNotifier />}
						{isMobile ? <span /> : <SwitchOrganization />}
						{!isMobile && <Notifications />}
						<HeaderUserMenu user={user} workspaces={workspaces} />
					</div>
				</div>
				{/* end::Wrapper */}
			</div>
		</div>
	);
};

export default Header;
