import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiDelete, apiGetWithSchema, apiPost, apiPut } from "./api";
import { ArrayOfMetadata } from "@metronome/types/Metadata";
import { toast } from "sonner";

const metadataKeys = {
	single: (workspaceId: string, stepInstanceId: string) => [
		workspaceId,
		"metadata",
		stepInstanceId,
	],
};

export function useMetadata(stepInstanceId: string) {
	const workspaceId = useWorkspaceId();
	return useQuery({
		queryKey: metadataKeys.single(workspaceId, stepInstanceId),
		queryFn: () =>
			apiGetWithSchema<ArrayOfMetadata>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/metadata-values`,
				ArrayOfMetadata,
			),
	});
}

type CreateMetadataBody = { definitionId: string; value: string | number };
export function useCreateMetadata(nodeId: string, stepInstanceId: string) {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ definitionId, value }: CreateMetadataBody) =>
			apiPost(`/ws/${workspaceId}/nodes/${nodeId}/metadata-values`, {
				definitionId,
				value,
			}),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: metadataKeys.single(workspaceId, stepInstanceId),
			});
			toast.success("Success");
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export function useUpdateMetadata(
	nodeId: string,
	valueId: string,
	stepInstanceId: string,
) {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ value, definitionId }: CreateMetadataBody) =>
			apiPut(`/ws/${workspaceId}/nodes/${nodeId}/metadata-values/${valueId}`, {
				value,
				definitionId,
			}),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: metadataKeys.single(workspaceId, stepInstanceId),
			});
			toast.success("Success");
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export function useDeleteMetadata(
	nodeId: string,
	valueId: string,
	stepInstanceId: string,
) {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: () =>
			apiDelete(
				`/ws/${workspaceId}/nodes/${nodeId}/metadata-values/${valueId}`,
			),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: metadataKeys.single(workspaceId, stepInstanceId),
			});
			toast.success("Success");
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}
