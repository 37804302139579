import { ErrorBoundary } from "@sentry/react";
import {
	type ColumnDef,
	type ColumnFiltersState,
	type ColumnOrderState,
	type ColumnPinningState,
	type PaginationState,
	type SortingState,
	type Updater,
	createColumnHelper,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
	type VisibilityState,
} from "@tanstack/react-table";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";

import {
	useProcessInstances,
	useProcessInstancesFromBatch,
	useProcessInstancesFromCluster,
} from "@metronome/api/useProcessInstance";
import type { SavedTable } from "@metronome/api/useSaveTableViews";
import * as DataGrid from "@metronome/components/DataGrid";
import Flag from "@metronome/components/Flag";
import { HighlightWords } from "@metronome/components/HighlightWords";
import { Lozenge } from "@metronome/components/Lozenge";
import { ProgressBar } from "@metronome/components/Progress";
import { TableContainer } from "@metronome/components/TableContainer/TableContainer";
import { TimelinessLozenge } from "@metronome/components/TimelinessTag";
import { Button } from "@metronome/components/ui/button";
import { useActiveViewData } from "@metronome/context/ActiveViewData";
import { SelectCustomView } from "@metronome/features/SelectCustomView";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type {
	IProcessInstance,
	ProcessContext,
	ProcessState,
	Time,
} from "@metronome/types/ProcessInstance";
import type { UserPreference } from "@metronome/types/UserPreference";
import { atEndOfDay, atMidnight } from "@metronome/utils/dateHelpers";
import { isWithinRange } from "@metronome/utils/isWithinRange";
import { getHexColorFromStatusClass } from "@metronome/utils/status";
import { fuzzyFilter } from "@metronome/utils/tableHelper";
import { getTimelinessFromTimeline } from "@metronome/utils/timeliness";
import { GearIcon } from "@radix-ui/react-icons";
import { ETimeliness } from "@metronome/types/Timeliness";
import type { IFilters } from "@metronome/types/StepInstance";
import { LABELS } from "@metronome/constants/stepInstanceColumns";

const FLAG = "isFlagged";
const PLANNED_ON = "schedule.lowerTimeBand";
const DUE_ON = "schedule.upperTimeBand";

const EMPTY_ARRAY: Array<never> = [];
const EMPTY_OBJECT: Record<string, never> = {};

const columnHelpers = createColumnHelper<IProcessInstance>();

type LinkHighLightedProps = {
	workspaceId: string;
	contextId: string;
	streamId: string;
	value: string | number;
	query: string;
};

type LinkWrapperProps = {
	workspaceId: string;
	contextId: string;
	streamId: string;
	children: React.ReactNode;
};

const LinkHighLighted: React.FC<LinkHighLightedProps> = ({
	contextId,
	workspaceId,
	streamId,
	value,
	query,
}) => (
	<Link
		to="/$workspaceId/stream/$streamId/process/$processId"
		params={{
			workspaceId,
			streamId,
			processId: contextId,
		}}
	>
		<HighlightWords content={value} query={query} />
	</Link>
);

const LinkWrapper: React.FC<LinkWrapperProps> = ({
	contextId,
	workspaceId,
	streamId,
	children,
}) => (
	<Link
		to="/$workspaceId/stream/$streamId/process/$processId"
		params={{
			workspaceId,
			streamId,
			processId: contextId,
		}}
	>
		{children}
	</Link>
);

function useColumns(
	context: ProcessContext,
	labelsFilter: IFilters["labels"],
	streamId: string,
): ColumnDef<IProcessInstance>[] {
	const workspaceId = useWorkspaceId();
	const intl = useIntl();

	const closedProcessColumns = useMemo(
		() => [
			columnHelpers.accessor("schedule.closedAt", {
				id: "CLOSED_ON",
				filterFn: isWithinRange,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="CLOSED_ON" />,
				cell: (info) => (
					<LinkWrapper
						contextId={info.row.original.id}
						streamId={streamId}
						workspaceId={workspaceId}
					>
						<FormattedDate value={new Date(info.getValue() ?? "")} />
					</LinkWrapper>
				),
			}),
		],
		[workspaceId, streamId],
	);

	const columns = useMemo(
		() => [
			columnHelpers.accessor("name", {
				id: "name",
				enableColumnFilter: false,
				enableSorting: false,
				footer: (props) => props.column.id,
				header: () => (
					<FormattedMessage id="PROCESS_STREAM.INSTANCES.OCCURENCES" />
				),
				cell: (info) => (
					<LinkHighLighted
						contextId={info.row.original.id}
						workspaceId={workspaceId}
						streamId={streamId}
						value={info.getValue() ?? ""}
						query={info.table.getState().globalFilter}
					/>
				),
			}),
			columnHelpers.accessor(PLANNED_ON, {
				id: PLANNED_ON,
				filterFn: isWithinRange,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="PLANNED_START" />,
				cell: (info) => (
					<LinkWrapper
						contextId={info.row.original.id}
						streamId={streamId}
						workspaceId={workspaceId}
					>
						<FormattedDate value={new Date(info.getValue() ?? "")} />
					</LinkWrapper>
				),
			}),
			columnHelpers.accessor(DUE_ON, {
				id: DUE_ON,
				filterFn: isWithinRange,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="PLANNED_END" />,
				cell: (info) => (
					<LinkWrapper
						contextId={info.row.original.id}
						streamId={streamId}
						workspaceId={workspaceId}
					>
						<FormattedDate value={new Date(info.getValue() ?? "")} />
					</LinkWrapper>
				),
			}),
			columnHelpers.accessor((row) => row.context.labels, {
				id: LABELS,
				enableSorting: false,
				enableColumnFilter: false,
				meta: {
					modelRefArray: labelsFilter,
				},
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="LABELS" />,
				cell: (info) => (
					<div className="flex gap-2">
						{Array.from(info.getValue())?.map((label: string) => (
							<Lozenge key={label} appearance="default">
								{label}
							</Lozenge>
						))}
					</div>
				),
				filterFn: "arrIncludes",
			}),
			columnHelpers.accessor("progress", {
				id: "progress",
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => (
					<FormattedMessage id="PROCESS_STREAM.INSTANCES.PROGRESS" />
				),
				cell: (info) =>
					info.row.original.state === "closed" ? (
						<FormattedMessage id="CLOSED" />
					) : (
						<ProgressBar
							progress={info.getValue() ?? 0}
							colorCouple={getHexColorFromStatusClass(
								info.row.original.resolution,
								getTimelinessFromTimeline(info.row.original.schedule),
							)}
						/>
					),
			}),
			columnHelpers.accessor("resolution", {
				id: "resolution",
				enableSorting: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="TIMELINESS" />,
				cell: (info) => (
					<TimelinessLozenge
						timeliness={getTimelinessFromTimeline(info.row.original.schedule)}
					/>
				),
				meta: {
					modelRefArray: [ETimeliness.enum.onTrack, ETimeliness.enum.late].map(
						(p) => ({
							id: p,
							name: intl.formatMessage({ id: `TIMELINESS.${p}` }),
						}),
					),
				},
			}),
			columnHelpers.accessor("isFlagged", {
				id: FLAG,
				enableColumnFilter: false,
				enableSorting: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="FLAG" />,
				cell: (info) => (
					<Flag
						isFlagged={!!info.getValue()}
						id={info.row.original.id}
						context="process-instances"
						parentId={info.row.original.processStream.id}
					/>
				),
			}),
			columnHelpers.accessor((row) => row.nextStep?.name ?? "", {
				id: "nextStep.name",
				enableSorting: false,
				enableColumnFilter: true,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="PROCESS_STREAM.INSTANCES.STEPS" />,
				cell: (info) => (
					<LinkHighLighted
						contextId={info.row.original.id}
						streamId={streamId}
						workspaceId={workspaceId}
						value={info.getValue() ?? ""}
						query={info.table.getState().globalFilter}
					/>
				),
			}),
			columnHelpers.accessor("processTemplate.versionName", {
				id: "versionName",
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => (
					<FormattedMessage id="PROCESS_STREAM.INSTANCES.VERSION" />
				),
				cell: (info) => (
					<LinkHighLighted
						contextId={info.row.original.id}
						streamId={streamId}
						workspaceId={workspaceId}
						value={info.getValue() ?? ""}
						query={info.table.getState().globalFilter}
					/>
				),
			}),
		],
		[workspaceId, streamId, intl, labelsFilter],
	);

	if (context === "past")
		return [
			...columns,
			...closedProcessColumns,
		] as ColumnDef<IProcessInstance>[];
	return columns as ColumnDef<IProcessInstance>[];
}

const FallbackProcessInstance: React.FC = () => (
	<div className="text-center font-bold">
		<FormattedMessage id="PROCESSES_ERROR" />
	</div>
);

export const ProcessInstancesTab: React.FC<{
	states: ProcessState[];
	preferenceKey: string;
	streamId: string;
	context: ProcessContext;
	labelsFilter: IFilters["labels"];
	preferences?: UserPreference;
	times?: Time[];
	savedViews?: SavedTable[];
	workspaceViews?: SavedTable[];
}> = ({
	times,
	labelsFilter,
	streamId,
	states,
	preferences,
	preferenceKey,
	context,
	savedViews,
	workspaceViews,
}) => {
	const workspaceId = useWorkspaceId();
	const { selectedBatch, selectedCluster } = useSearch({
		strict: false,
	});
	const { activeView } = useActiveViewData();
	const [dirty, setDirty] = useState(false);
	const navigate = useNavigate();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: preferences?.pageSize ?? 10,
	});

	const columns = useColumns(context, labelsFilter, streamId);

	const initialColumnVisibility = useMemo(() => {
		if (savedViews?.length && activeView?.type === "userView") {
			const columnVisibility = savedViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnVisibility;

			if (columnVisibility) {
				return columnVisibility;
			}
		}
		if (workspaceViews?.length && activeView?.type === "workspaceView") {
			const columnVisibility = workspaceViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnVisibility;

			if (columnVisibility) {
				return columnVisibility;
			}
		}

		return {
			name: true,
			expander: true,
		};
	}, [activeView?.id, activeView?.type, savedViews, workspaceViews]);

	const initialColumnOrder = useMemo(() => {
		if (savedViews && activeView?.type === "userView") {
			const columnOrder = savedViews.find((view) => view.id === activeView.id)
				?.settings.columnOrder;
			if (columnOrder) {
				return columnOrder;
			}
		}
		if (workspaceViews && activeView?.type === "workspaceView") {
			const columnOrder = workspaceViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnOrder;
			if (columnOrder) {
				return columnOrder;
			}
		}
		return columns.map((col, i) => col.id ?? i.toString());
	}, [activeView?.id, activeView?.type, columns, savedViews, workspaceViews]);

	const initialColumnFilters = useMemo(() => {
		if (savedViews && activeView?.type === "userView") {
			const columnFilters = savedViews.find((view) => view.id === activeView.id)
				?.settings.columnFilters;
			if (columnFilters) {
				return columnFilters;
			}
		}
		if (workspaceViews && activeView?.type === "workspaceView") {
			const columnFilters = workspaceViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnFilters;
			if (columnFilters) {
				return columnFilters;
			}
		}
		return EMPTY_ARRAY;
	}, [savedViews, activeView?.type, activeView?.id, workspaceViews]);

	const initialColumnPinning = useMemo(() => {
		if (savedViews && activeView?.type === "userView") {
			const columnPinning = savedViews.find((view) => view.id === activeView.id)
				?.settings.columnPinning;
			if (columnPinning) {
				return columnPinning;
			}
		}
		if (workspaceViews && activeView?.type === "workspaceView") {
			const columnPinning = workspaceViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnPinning;
			if (columnPinning) {
				return columnPinning;
			}
		}
		return EMPTY_OBJECT;
	}, [activeView?.id, activeView?.type, savedViews, workspaceViews]);

	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
		initialColumnVisibility,
	);

	const [columnPinning, setColumnPinning] =
		useState<ColumnPinningState>(initialColumnPinning);

	const [columnFilters, setColumnFilters] =
		useState<ColumnFiltersState>(initialColumnFilters);
	const [columnOrder, setColumnOrder] =
		useState<ColumnOrderState>(initialColumnOrder);

	const [powerMode, setPowerMode] = useState(false);
	const [globalFilter, setGlobalFilter] = useState("");

	const defaultSorting = states?.includes("closed")
		? [{ desc: true, id: "schedule_plannedOn" }]
		: EMPTY_ARRAY;

	const [sorting, setSorting] = React.useState<SortingState>(defaultSorting);

	const useFetchGetter = () => {
		if (selectedBatch) {
			return useProcessInstancesFromBatch;
		}
		if (selectedCluster) {
			return useProcessInstancesFromCluster;
		}
		return useProcessInstances;
	};
	const {
		data: processStreamInstances,
		isLoading,
		isFetching,
		isFetched,
	} = useFetchGetter()({
		batchId: selectedBatch,
		clusterId: selectedCluster,
		streamId,
		page: pagination.pageIndex + 1,
		pageSize: pagination.pageSize,
		search: globalFilter,
		sortOrder: sorting?.[0]?.desc ? "desc" : "asc",
		sortBy: sorting?.[0]?.id.replace("_", "."),
		labelIds: columnFilters?.find((filter) => filter.id === LABELS)
			?.value as string[],
		responsibleIds: columnFilters?.find(
			(filter) => filter.id === "userRoleAssignmentsMap.responsibleAssignees",
		)?.value as string[],
		plannedStartFrom: columnFilters.length
			? atMidnight(
					(
						columnFilters.find((filter) => filter.id === PLANNED_ON)
							?.value as string[]
					)?.at(0),
				)?.toJSON()
			: undefined,
		plannedStartTo: columnFilters.length
			? atEndOfDay(
					(
						columnFilters.find((filter) => filter.id === PLANNED_ON)
							?.value as string[]
					)?.at(1),
				)?.toJSON()
			: undefined,
		plannedEndFrom: columnFilters.length
			? atMidnight(
					(
						columnFilters.find((filter) => filter.id === DUE_ON)
							?.value as string[]
					)?.at(0),
				)?.toJSON()
			: undefined,
		plannedEndTo: columnFilters.length
			? atEndOfDay(
					(
						columnFilters.find((filter) => filter.id === DUE_ON)
							?.value as string[]
					)?.at(1),
				)?.toJSON()
			: undefined,
		states,
		plannedStartTimes: times,
	});
	const instance = useReactTable<IProcessInstance>({
		data: processStreamInstances?.results ?? [],
		columns,
		columnResizeMode: "onChange",
		getCoreRowModel: getCoreRowModel(),
		pageCount: processStreamInstances?.totalPages,
		filterFns: {
			fuzzy: fuzzyFilter,
			isWithinRange,
		},
		state: {
			pagination,
			columnPinning,
			columnVisibility,
			columnFilters,
			columnOrder,
			sorting,
			globalFilter,
		},
		onPaginationChange: setPagination,
		manualPagination: true,
		manualFiltering: true,
		manualSorting: true,
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: fuzzyFilter,
		onColumnPinningChange: (updaterOrValue: Updater<ColumnPinningState>) => {
			setColumnPinning(updaterOrValue);
			setDirty(true);
		},
		onColumnVisibilityChange: (updaterOrValue: Updater<VisibilityState>) => {
			setColumnVisibility(updaterOrValue);
			setDirty(true);
		},
		onColumnFiltersChange: (updaterOrValue: Updater<ColumnFiltersState>) => {
			setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
			setColumnFilters(updaterOrValue);
			setDirty(true);
		},
		onColumnOrderChange: (updaterOrValue: Updater<ColumnOrderState>) => {
			setColumnOrder(updaterOrValue);
			setDirty(true);
		},
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	const goToItem = useCallback(
		(item: Pick<IProcessInstance, "id">): void => {
			navigate({
				to: "/$workspaceId/stream/$streamId/process/$processId",
				params: {
					workspaceId,
					streamId,
					processId: item.id,
				},
			});
		},
		[navigate, streamId, workspaceId],
	);

	const switchPowerMode = useCallback(
		() => setPowerMode((powerMode) => !powerMode),
		[],
	);

	return (
		<div className="w-full">
			<DataGrid.TableHeader<IProcessInstance>
				isLoading={isLoading}
				instance={instance}
				globalFilter={globalFilter}
			>
				<Button onClick={switchPowerMode} size="icon" variant="ghost">
					<GearIcon />
				</Button>
				<SelectCustomView key={preferenceKey} preferenceKey={preferenceKey} />
			</DataGrid.TableHeader>
			<div className="flex flex-row overflow-auto gap-2 w-full min-h-[35vh]">
				<TableContainer>
					<DataGrid.SidePanel
						instance={instance}
						setPowerMode={switchPowerMode}
						powerMode={powerMode}
						preferenceKey={preferenceKey}
						savedViews={savedViews}
						activeView={activeView}
						dirty={dirty}
						setDirty={setDirty}
					/>
					<div className="flex flex-col grow overflow-auto w-[calc(100vw-180px)]">
						<ErrorBoundary fallback={<FallbackProcessInstance />}>
							<DataGrid.TableBody<IProcessInstance>
								instance={instance}
								powerMode={powerMode}
								goToItem={goToItem}
								isFetching={isLoading}
								isFetched={isFetched}
							/>
						</ErrorBoundary>
					</div>
				</TableContainer>
			</div>
			<DataGrid.Footer<IProcessInstance>
				instance={instance}
				numberOfResults={processStreamInstances?.totalItems ?? 0}
				setPagination={setPagination}
				isFetching={isFetching}
				isSearching={isFetching}
				isFetched={isFetched}
			/>
		</div>
	);
};
