import type React from "react";
import { useEffect, useState } from "react";
import { Input } from "./ui/input";
import { UpdateIcon } from "@radix-ui/react-icons";

// A debounced input react component
export const DebouncedInput = ({
	isLoading,
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}: {
	value: string | number;
	onChange: (value: string | number) => void;
	isLoading?: boolean;
	debounce?: number;
} & Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	"onChange"
>): JSX.Element => {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [value]);

	return (
		<div className="relative">
			<Input
				{...props}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
			{!!isLoading && (
				<UpdateIcon className="absolute right-2 top-1/2 -translate-y-1/2 animate-spin text-slate-500" />
			)}
		</div>
	);
};

export default DebouncedInput;
