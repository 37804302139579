import { differenceInDays } from "date-fns";

type DifferenceInDaysProps = {
	from: string;
	to: string;
};
export const DifferenceInDays: React.FC<DifferenceInDaysProps> = ({
	from,
	to,
}) => {
	const numberOfDays = differenceInDays(from, to);
	if (numberOfDays <= 0) {
		return null;
	}
	return <span>{`+${numberOfDays}d`}</span>;
};
