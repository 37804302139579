import { z } from "zod";

export const ITimeline = z.object({
	referenceDate: z.string(),
	isAllDay: z.boolean(),
	lowerTimeBand: z.string(),
	upperTimeBand: z.string(),
	startedAt: z.string().optional(),
	closedAt: z.string().optional(),
});

export type ITimeline = z.infer<typeof ITimeline>;
