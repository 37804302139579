import { useCreateAssignments } from "@metronome/api/useAssignments";
import { useGetAvailablePartiesInfinite } from "@metronome/api/useParty";
import { ComboboxPaginated } from "@metronome/components/ComboBoxPaginated";
import { Button } from "@metronome/components/ui/button";
import type { RoleAndPartyAssignments } from "@metronome/types/PartyAssignment";

import { useCallback, useState } from "react";
import type { MouseEvent } from "react";
import { useIntl } from "react-intl";

type AvailablePartiesModalProps = {
	roleId: string;
	stepInstanceId: string;
	onSuccess: () => void;
	applicationLevel: RoleAndPartyAssignments["applicationLevel"];
};

type Option = {
	label: string;
	value: string;
	icon?: string;
};

export const AvailablePartiesModal: React.FC<AvailablePartiesModalProps> = ({
	roleId,
	stepInstanceId,
	onSuccess,
	applicationLevel,
}) => {
	const intl = useIntl();
	const [search, setSearch] = useState("");
	const [partyId, setPartyId] = useState("");

	const { mutate: updateAssignment, isPending } = useCreateAssignments(
		"step-instances",
		stepInstanceId,
		applicationLevel,
		onSuccess,
	);

	const {
		data: partiesAvailable,
		isLoading,
		fetchNextPage,
		hasNextPage,
	} = useGetAvailablePartiesInfinite(roleId, stepInstanceId, search);

	const partiesToSelect = !isLoading
		? (partiesAvailable?.pages
				?.flatMap((page) => page?.results)
				.map((party) => ({
					label: party?.firstName,
					value: party?.id,
					icon: party?.profilePictureUri,
				})) as Option[])
		: [];

	const onClickSaveAssignments = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			updateAssignment({ partyId, roleId });
		},
		[updateAssignment, partyId, roleId],
	);

	return (
		<form key={stepInstanceId} className="flex flex-col gap-4">
			<section className="flex flex-col gap-2">
				<ComboboxPaginated
					setSearchValue={setSearch}
					value={partyId}
					setValue={setPartyId}
					items={partiesToSelect}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
				/>
			</section>
			<div className="flex flex-row justify-end">
				<Button
					disabled={isPending || !roleId || !partyId}
					onClick={onClickSaveAssignments}
				>
					{isPending
						? intl.formatMessage({ id: "LOADING" })
						: intl.formatMessage({
								id: "SAVE",
							})}
				</Button>
			</div>
		</form>
	);
};
