import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateFileAttachment } from "@metronome/api/useAttachments";
import type { BaseParams } from "@metronome/api/useAttachments";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import {
	ImgThumbnail,
	PdfThumbnail,
	useObjectUrls,
} from "@metronome/components/FileThumbnail";
import FloatingInput from "@metronome/components/FloatingInput";
import { Button } from "@metronome/components/ui/button";
import { AttachmentTypeAPI } from "@metronome/types/Attachment";
import { canvasToFile, urlToFile } from "@metronome/utils/fileHelpers";
import type React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./sendFileAttachment.module.scss";
import { CameraIcon, FileIcon } from "@radix-ui/react-icons";
import { Badge } from "@metronome/components/ui/badge";
import { toast } from "sonner";
import { useIsMobile } from "@metronome/hooks/useResponsive";

type FilePlaceholderProps = {
	children: React.ReactNode;
};
const FilePlaceholder: React.FC<FilePlaceholderProps> = ({ children }) => (
	<div className="h-52 overflow-clip p-2 border-dashed border-green-700 border rounded-md flex flex-col items-center justify-center gap-2">
		{children}
	</div>
);

const ATTACHMENT_MAX_SIZE = 5_000_000; // Size 1MB should be 1*1024*1024
const ALLOWED_IMAGE_FILE_TYPES = ["image/png", "image/jpeg"];
const PDF = "application/pdf";

type SendFileAttachmentProps = {
	context: BaseParams["context"];
	contextId: string;
	onSuccess?: () => void;
	specId?: string;
	children?: React.ReactNode;
};

export const SendFileAttachment: React.FC<SendFileAttachmentProps> = ({
	context,
	contextId,
	onSuccess,
	specId,
}) => {
	const intl = useIntl();
	const isMobile = useIsMobile();

	const [file, setFile] = useState<File>();
	const [canvas, setCanvas] = useState<HTMLCanvasElement>();
	const [previewUri, setPreviewUri] = useState("");

	const { mutateAsync: createAttachment, isPending } = useCreateFileAttachment(
		context,
		contextId,
		onSuccess,
	);

	const handleSubmit: React.ChangeEventHandler<HTMLFormElement> = async (
		event,
	) => {
		event.preventDefault();
		if (!file) return;
		let previewFile = undefined;
		const fileName = file?.name.replace(/\.[^/.]+$/, "") ?? "undefined";
		if (canvas && file?.type === PDF) {
			previewFile = await canvasToFile(canvas, fileName);
		} else if (
			previewUri &&
			ALLOWED_IMAGE_FILE_TYPES.includes(file?.type ?? "")
		) {
			previewFile = await urlToFile(previewUri, fileName);
		}

		const formData = new FormData(event.target);
		const data = {} as Record<string, string>;

		for (const [key, value] of formData.entries()) {
			if (typeof value === "string") {
				data[key] = value;
			}
		}

		const fileSize = file.size ?? 0;
		if (fileSize >= ATTACHMENT_MAX_SIZE) {
			toast.error(
				intl.formatMessage(
					{ id: "ERRORS.FILE_LIMIT" },
					{ size: ATTACHMENT_MAX_SIZE / 1000000 },
				),
			);
			return;
		}

		createAttachment({
			type: AttachmentTypeAPI.enum.Document,
			name: data.name,
			description: data.description ?? "",
			file: file,
			previewFile: previewFile,
			specId: specId ?? undefined,
		});
	};

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newFile = e.target.files?.[0];
		if ((newFile as File).size >= ATTACHMENT_MAX_SIZE) {
			toast.error(
				intl.formatMessage(
					{
						id: "ERRORS.FILE_LIMIT",
					},
					{ size: ATTACHMENT_MAX_SIZE / 1000000 },
				),
			);
			return;
		}
		if (
			newFile &&
			(newFile as File).size <= ATTACHMENT_MAX_SIZE &&
			(newFile.type === PDF || ALLOWED_IMAGE_FILE_TYPES.includes(newFile.type))
		) {
			setFile(newFile);
			// setValue("name", newFile.name);
		}
	};

	const getObjectUrl = useObjectUrls();

	return (
		<form
			className="flex flex-col gap-2 sm:w-72 md:w-96 mt-2"
			onSubmit={handleSubmit}
		>
			<div className="flex gap-2 items-center">
				{isMobile ? (
					<label htmlFor="camera">
						<Badge variant="secondary" className="p-2">
							<CameraIcon className="inline me-2" />
							<FormattedMessage id="TAKE_PICTURE" />
						</Badge>

						<input
							className="hidden"
							type="file"
							capture="environment"
							id="camera"
							name="file"
							onChange={onFileChange}
						/>
					</label>
				) : (
					<label htmlFor="file">
						<Badge className="p-2">
							<FileIcon className="inline me-2" />
							<FormattedMessage id="SEND_ATTACHMENT_FILE" />
						</Badge>

						<input
							className="hidden"
							type="file"
							id="file"
							name="file"
							onChange={onFileChange}
						/>
					</label>
				)}
			</div>

			{!!file && (
				<FilePlaceholder>
					{!!file && ALLOWED_IMAGE_FILE_TYPES.includes(file.type) && (
						<ImgThumbnail
							file={file}
							onChange={(previewUri) => setPreviewUri(previewUri)}
						/>
					)}
					{!!file && file.type === PDF && (
						<PdfThumbnail
							pdfUrl={getObjectUrl(file)}
							fileName={file.name}
							onChange={(previewFile) => setCanvas(previewFile)}
						/>
					)}
				</FilePlaceholder>
			)}
			<div
				className={`${file ? "opacity-100" : "opacity-0 h-0"} transition-all flex flex-col gap-2`}
			>
				<FloatingInput required label="name" name="name" />
				<FloatingInput
					required={false}
					label="description"
					name="description"
				/>
			</div>
			<input
				type="submit"
				disabled={!file || isPending}
				className={styles.submit}
				value={
					isPending
						? intl.formatMessage({ id: "LOADING" })
						: intl.formatMessage({ id: "SUBMIT" })
				}
			/>
		</form>
	);
};

export const SendFileAttachmentAsDialog: React.FC<SendFileAttachmentProps> = ({
	context,
	contextId,
	children,
	onSuccess,
	specId,
}): JSX.Element => {
	const [open, setOpen] = useState(false);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button>
					{children || <FontAwesomeIcon fixedWidth icon={["fas", "upload"]} />}
				</Button>
			</DialogTrigger>
			<DialogContent size="medium">
				<SendFileAttachment
					context={context}
					contextId={contextId}
					specId={specId}
					onSuccess={() => {
						setOpen(false);
						onSuccess?.();
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
