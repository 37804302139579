import {
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@metronome/components/ui/table";
import type { EResolution } from "@metronome/types/Resolution";
import type { ISchedule, IStepInstance } from "@metronome/types/StepInstance";
import { FormattedMessage } from "react-intl";
import { formatDurationToString } from "@metronome/utils/durationHelpers";
import { CustomItemSchedule } from "./UpdateStepInstanceSchedule";
import { calcTillDate } from "@metronome/utils/planby";
import { LockClosedIcon } from "@radix-ui/react-icons";
import { isProcessActive } from "@metronome/utils/isProcessActive";
import type { ProcessState } from "@metronome/types/ProcessInstance";
import { UpdateResolution } from "./UpdateResolution";

type StepStateProps = {
	schedule: ISchedule;
	resolution: EResolution;
	stepInstanceId: string;
	processInstanceId: string;
	type: IStepInstance["type"];
	processState: ProcessState;
};
export const StepState: React.FC<StepStateProps> = ({
	schedule,
	resolution,
	stepInstanceId,
	processInstanceId,
	processState,
	type,
}) => {
	const duration = formatDurationToString(
		schedule.estimatedDuration ?? schedule?.plannedDuration,
	);

	const isFromAnActiveProcess = isProcessActive(processState);
	return (
		<Table className="bg-white">
			<TableBody>
				<TableRow>
					<TableCell className="capitalize font-semibold">
						<FormattedMessage id="START" /> / <FormattedMessage id="END" />
					</TableCell>
					<TableCell>
						<CustomItemSchedule
							stepId={stepInstanceId}
							since={schedule.plannedAt ?? schedule.scheduleLowerBand}
							till={calcTillDate(schedule)}
							schedule={schedule}
							processId={processInstanceId}
						/>
					</TableCell>
				</TableRow>

				{!!duration && (
					<TableRow>
						<TableCell className="capitalize font-semibold">
							<FormattedMessage id="DURATION" />
						</TableCell>
						<TableCell>{duration}</TableCell>
					</TableRow>
				)}
				<TableRow>
					<TableCell className="capitalize font-semibold">
						<FormattedMessage id="STATUS" />
					</TableCell>
					<TableCell>
						{!isFromAnActiveProcess && (
							<>
								<LockClosedIcon />
								<FormattedMessage id="WARNING_PROCESS_CLOSED" />
							</>
						)}
						<UpdateResolution
							stepInstanceId={stepInstanceId}
							processState={processState}
							processId={processInstanceId}
							type={type}
							resolution={resolution}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};
