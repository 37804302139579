import { z } from "zod";
import { IModelReference } from "./ModelReference";

const NodeType = z.enum([
	"root",
	"clustering",
	"sequencing",
	"asset",
	"transaction",
]);

const References = z.object({
	referencedNode: IModelReference,
	createdBy: z.object({
		type: z.enum(["system"]),
		name: z.string(),
	}),
	origin: z.enum(["regulation", "planning"]),
	validFrom: z.string().optional(),
	validTo: z.string().optional(),
});

export const Resource = z.object({
	nodeReferenceDefinitionName: z.string(),
	nodeReferenceDefinitionId: z.string(),
	ownershipLevel: z.enum(["step", "process"]),
	references: z.array(References).optional(),
	allowMultiple: z.boolean(),
});
export type Resource = z.infer<typeof Resource>;

export const ResourcesArray = z.array(Resource);
export type ResourcesArray = z.infer<typeof ResourcesArray>;
