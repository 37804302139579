import { z } from "zod";

export const IPagination = z.object({
	page: z.number(),
	pageSize: z.number(),
});
export type IPagination = z.infer<typeof IPagination>;

export interface IPaginatedResults<T> extends IPagination {
	results: Array<T>;
	totalItems: number;
	totalPages: number;
	nextPage: number;
	prevPage: number;
}

export const CursorPagination = z.object({
	pageSize: z.number(),
	nextCursor: z.string().optional(),
	currentCursor: z.string().optional(),
});
export type CursorPagination = z.infer<typeof CursorPagination>;

export const ICursorPaginatedResults = CursorPagination.extend({
	results: z.array(z.any()),
});
export interface ICursorPaginatedResults<T> extends CursorPagination {
	results: Array<T>;
}

export const DoubleCursorPagination = z.object({
	countPrevious: z.number(),
	countNext: z.number(),
	cursorPrevious: z.string().optional(),
	cursorNext: z.string().optional(),
});
export type DoubleCursorPagination = z.infer<typeof DoubleCursorPagination>;

export interface IDoubleCursorPaginatedResults<T>
	extends DoubleCursorPagination {
	results: Array<T>;
}

export const PaginationComplete = z.object({
	totalItems: z.number(),
	totalPages: z.number(),
	nextPage: z.number(),
	prevPage: z.number(),
	page: z.number(),
	pageSize: z.number(),
});
export type PaginationComplete = z.infer<typeof PaginationComplete>;
