import type React from "react";
import { useCallback, useRef } from "react";
import { Input } from "@metronome/components/ui/input";
import type { Context } from "@metronome/types/Context";
import { getInputType } from "@metronome/utils/metadataType";
import { MultiSelectMetadata } from "./MultiSelectMetadata";
import {
	useCreateMetadata,
	useUpdateMetadata,
} from "@metronome/api/useMetadata";
import type { Metadata, MetadataValue } from "@metronome/types/Metadata";

type EditableMetadataValuesProps = {
	contextId: string;
	context: Context;
	nodeId: string;
	definition: Metadata["definition"];
	values: MetadataValue | undefined;
};

type EditModeMetadataValuesProps = {
	definition: Metadata["definition"];
	contextId: string;
	context: Context;
	nodeId: string;
	metadataValue: MetadataValue | undefined;
};

const EditModeMetadataValues: React.FC<EditModeMetadataValuesProps> = ({
	definition,
	contextId,
	nodeId,
	metadataValue,
}) => {
	const { mutate: createMetadata } = useCreateMetadata(nodeId, contextId);
	const { mutate: updateMetadata } = useUpdateMetadata(
		nodeId,
		metadataValue?.id as string,
		contextId,
	);

	const inputRef = useRef<HTMLInputElement | null>(null);

	const submitInput: React.ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = useCallback(
		(e) => {
			if (!e.target.value || e.target.value === metadataValue?.value) {
				return;
			}
			if (!metadataValue) {
				createMetadata({
					definitionId: definition.id,
					value: e.target.value,
				});
			} else {
				updateMetadata({
					definitionId: definition.id,
					value: e.target.value,
				});
			}
		},
		[definition.id, createMetadata, updateMetadata, metadataValue],
	);

	return (
		<form className="w-full">
			{!!["text", "email", "date", "number", "integer", "url"].includes(
				definition.type,
			) && (
				<Input
					required
					onBlur={submitInput}
					name={definition.name}
					defaultValue={metadataValue?.value}
					placeholder={
						!metadataValue?.value ? `Remplir le ${definition.name}` : ""
					}
					type={getInputType(definition.type)}
					onClick={() => {
						if (getInputType(definition.type) === "date") {
							inputRef?.current?.showPicker();
						}
					}}
					onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
						e.key === "Enter" && e.preventDefault()
					}
					className=""
				/>
			)}
			{definition.type === "multiSelect" && (
				<MultiSelectMetadata
					definition={definition}
					metaData={metadataValue}
					mutateMetadata={createMetadata}
					nodeId={nodeId}
				/>
			)}
			{definition.type === "singleSelect" && (
				<select
					className="w-full"
					name={definition.name}
					onChange={submitInput}
					key={definition.id}
				>
					{definition?.enum?.map((option) => (
						<option key={option}>{option}</option>
					))}
				</select>
			)}
		</form>
	);
};

export const EditableMetadataValues: React.FC<EditableMetadataValuesProps> = ({
	contextId,
	context,
	nodeId,
	definition,
	values,
}) => {
	return (
		<>
			<div
				key={`definition-${definition.id}`}
				className="bg-white flex items-center p-2"
			>
				<strong className="truncate font-semibold">{definition.name}</strong>
			</div>
			<div className="flex flex-row gap-1 flex-wrap bg-white p-2">
				<EditModeMetadataValues
					contextId={contextId}
					context={context}
					nodeId={nodeId}
					definition={definition}
					metadataValue={values}
				/>
			</div>
		</>
	);
};
