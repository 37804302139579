import { z } from "zod";

export const EMetadataType = z.enum([
	"text",
	"integer",
	"date",
	"singleSelect",
	"multiSelect",
	"number",
	"url",
	"email",
	"dateTime",
	"time",
	"referenceData",
]);
export type EMetadataType = z.infer<typeof EMetadataType>;

export const IMetadataDefinition = z.object({
	id: z.string().min(1),
	name: z.string(),
	type: EMetadataType,
	allowMany: z.boolean(),
	isRequired: z.boolean(),
	isFeatured: z.boolean(),
	canBeEdited: z.boolean(),
	tree: z.object({
		id: z.string().min(1),
		name: z.string(),
	}),
	enum: z.array(z.string()).optional(),
	applicableNodeType: z
		.object({
			id: z.string().min(1),
			name: z.string(),
		})
		.optional(),
});
export type IMetadataDefinition = z.infer<typeof IMetadataDefinition>;

export const IMetaValue = z.object({
	id: z.string().min(1),
	value: z.union([z.string(), z.array(z.string())]),
});
export type IMetaValue = z.infer<typeof IMetaValue>;

export const ICreateableMetaValue = z.object({
	id: z.string().optional(),
	value: z.union([z.string(), z.array(z.string())]),
});
export type ICreateableMetaValue = z.infer<typeof ICreateableMetaValue>;
