import type { ILightBusinessDimensionNode } from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import { EditableMetadataValues } from "./EditableMetadataValues";
import { DisplayMetadataValue } from "./DisplayMetadataValues";
import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@metronome/components/Accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { FormattedMessage } from "react-intl";
import { useMetadata } from "@metronome/api/useMetadata";

type FeaturedMetadataProps = {
	contextId: string;
	context: Context;
	businessDimension: ILightBusinessDimensionNode;
};

export const FeaturedMetadata: React.FC<FeaturedMetadataProps> = ({
	contextId,
	context,
	businessDimension,
}) => {
	const { data: stepMetadata, isLoading } = useMetadata(contextId);

	if (isLoading) return null;

	if (!stepMetadata || stepMetadata?.length === 0) return null;

	return (
		<AccordionItem value="featured-metadata">
			<AccordionTrigger asChild>
				<div className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180">
					<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
					<span className="uppercase text-xs font-semibold">
						<FormattedMessage id="FEATURED_METADATA" />
					</span>
				</div>
			</AccordionTrigger>
			<AccordionContent>
				<div className="">
					<div className="grid grid-cols-2 bg-slate-200 p-0 gap-[1px] rounded overflow-clip">
						{stepMetadata.map(({ definition, values }) => {
							if (definition.canBeEdited)
								return (
									<EditableMetadataValues
										key={definition.id}
										nodeId={businessDimension.id}
										context={context}
										contextId={contextId}
										definition={definition}
										values={values[0]}
									/>
								);

							if (values.length && values[0]?.id)
								return (
									<DisplayMetadataValue
										key={definition.id}
										metadataValue={values[0]}
										definition={definition}
										valueId={values[0].id}
										nodeId={businessDimension.id}
										contextId={contextId}
									/>
								);
						})}
					</div>
				</div>
			</AccordionContent>
		</AccordionItem>
	);
};
