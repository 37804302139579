import { useOrganizationData } from "@metronome/context/OrganizationData";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IPaginatedResults } from "@metronome/types/PaginatedResponse";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import {
	useInfiniteQuery,
	infiniteQueryOptions,
	queryOptions,
} from "@tanstack/react-query";
import { apiGet } from "./api";

const processStreamKeys = {
	all: (
		workspaceId: string,
		organizationId?: string,
		processCategories?: string[],
	) =>
		[workspaceId, "processStreams", organizationId, processCategories] as const,
	single: (
		workspaceId: string,
		processStreamId: string,
		organizationId?: string,
	) =>
		[
			...processStreamKeys.all(workspaceId, organizationId),
			processStreamId,
		] as const,
	resourceAllocation: (
		workspaceId: string,
		organizationId: string,
		processStreamId: string,
	) => [
		workspaceId,
		"processStreams",
		organizationId,
		processStreamId,
		"resource-allocation",
	],
};

export const singleProcessStreamsOptions = (
	workspaceId: string,
	processStreamId: string,
	activeOrganization?: string,
) =>
	queryOptions({
		queryKey: processStreamKeys.single(
			workspaceId,
			processStreamId,
			activeOrganization,
		),

		queryFn: () =>
			apiGet<IProcessStream>(
				`ws/${workspaceId}/process-streams/${processStreamId}`,
			),
	});

type OptionsParams = {
	workspaceId: string;
	organizationId?: string;
	processCategories?: string[];
	pageSize: number;
};

const infiniteProcessStreamOptions = ({
	workspaceId,
	organizationId,
	processCategories,
	pageSize,
}: OptionsParams) =>
	infiniteQueryOptions({
		queryKey: processStreamKeys.all(
			workspaceId,
			organizationId ?? "",
			processCategories,
		),
		queryFn: ({ pageParam }) =>
			apiGet<IPaginatedResults<IProcessStream>>(
				`ws/${workspaceId}/process-streams`,
				{
					params: {
						organizationId,
						processCategories,
						page: pageParam,
						pageSize,
					},
				},
			),
		initialPageParam: 1,
		getNextPageParam: (
			lastPage: IPaginatedResults<IProcessStream> | undefined,
		): number | undefined => {
			if (lastPage && lastPage?.page >= lastPage?.totalPages) return undefined;
			return lastPage?.page && lastPage.page + 1;
		},
	});

export function useInfiniteProcessStreams(
	processCategories?: string[],
	pageSize?: number,
) {
	const workspaceId = useWorkspaceId();
	const { activeOrganization: organizationId } = useOrganizationData();

	return useInfiniteQuery(
		infiniteProcessStreamOptions({
			workspaceId,
			organizationId,
			processCategories,
			pageSize: pageSize ?? 10,
		}),
	);
}
