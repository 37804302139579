import { useStepInstancesFromProcesses } from "@metronome/api/useStepInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import type { IStage } from "@metronome/types/Gate";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import { format } from "date-fns";
import { useMemo } from "react";
import { useParams } from "@tanstack/react-router";
import { getTimelinessFromStepInstance } from "@metronome/utils/timeliness";
import { useProcessInstances } from "@metronome/api/useProcessInstance";
import { StepsTimeline } from "@metronome/features/StepsTimeline";
import { ErrorBoundary } from "@sentry/react";
import { SchedulerError } from "@metronome/components/SchedulerError";
import { calcTillDate } from "@metronome/utils/planby";
import { CustomChannelItem } from "@metronome/features/planby/CustomChannel";
import type { ProgramWithOmittedUuid } from "@nessprim/planby-pro/dist/Epg/helpers";

type ProcessesTimelineProps = {
	processes: IProcessInstance[];
	stages: Array<IStage[]>;
	processIds: string[];
	workspaceId: string;
};

export const ProcessTimelineLoader: React.FC<{
	streamId: string;
	batchId: string;
}> = ({ streamId, batchId }) => {
	const { workspaceId } = useParams({ strict: false });
	const { data: processes, isLoading: isProcessLoading } = useProcessInstances({
		streamId,
		states: ["active"],
		plannedStartTimes: ["past" as const, "today" as const],
		page: 1,
		pageSize: 20,
		// search: filter,
		// plannedStartFrom: dateRange?.length
		// 	? atMidnight(dateRange[0])?.toJSON()
		// 	: undefined,
		// plannedStartTo:
		// 	dateRange && dateRange.length > 1
		// 		? atEndOfDay(dateRange[1])?.toJSON()
		// 		: undefined,
		// plannedEndFrom: dateEndRange?.length
		// 	? atMidnight(dateEndRange[0])?.toJSON()
		// 	: undefined,
		// plannedEndTo:
		// 	dateEndRange?.length > 1
		// 		? atEndOfDay(dateEndRange[1])?.toJSON()
		// 		: undefined,
	});
	const processIds = processes?.results?.map((p) => p.id);
	const { data, pending } = useStepInstancesFromProcesses(processIds);

	return (
		<div className="bg-white">
			{(isProcessLoading || pending) && <LoadingMetronome />}

			<ErrorBoundary fallback={<SchedulerError />}>
				{!pending &&
					!!processes?.results?.length &&
					!!processIds &&
					!!workspaceId && (
						<ProcessesTimeline
							processes={processes.results}
							stages={data}
							processIds={processIds}
							workspaceId={workspaceId}
						/>
					)}
			</ErrorBoundary>
		</div>
	);
};

const ProcessesTimeline: React.FC<ProcessesTimelineProps> = ({
	processes,
	stages,
	processIds,
	workspaceId,
}) => {
	const plannedStart = processes[0].schedule.lowerTimeBand;
	const plannedEnd = processes[processes.length - 1].schedule.upperTimeBand;

	const channels = useMemo(
		() =>
			processes.map((p) => ({
				logo: "",
				uuid: p.id,
				title: p.name,
				progress: p.progress,
				schedule: p.schedule,
				resolution: p.resolution,
				workspaceId: workspaceId,
			})),
		[processes, workspaceId],
	);
	const epg: Array<ProgramWithOmittedUuid> = useMemo(
		() =>
			stages
				.flatMap((g, i) => {
					const channelUuid = processIds[i];
					return g.flatMap((step) =>
						step.steps.flatMap((instance) =>
							instance.stepInstances?.map((i) => {
								if (instance.stepInstances.length === 0) return;
								const since = format(
									i.schedule.plannedAt ?? i.schedule.scheduleLowerBand,
									"yyyy-MM-dd'T'HH:mm:ss",
								);
								const till = format(
									calcTillDate(i.schedule),
									"yyyy-MM-dd'T'HH:mm:ss",
								);
								const nodeName = i.businessDimension.name;
								const timeliness = getTimelinessFromStepInstance(
									i.schedule,
									undefined,
									i.type,
								);

								return {
									workspaceId,
									channelUuid,
									processId: channelUuid,
									// streamId:
									schedule: i.schedule,
									id: i.id,
									type: i.type,
									timeliness,
									title:
										instance.stepInstances.length > 1
											? `${nodeName} - ${instance.name}`
											: instance.name,
									since,
									till,
								};
							}),
						),
					);
				})
				.filter((s) => !!s),
		[processIds, stages, workspaceId],
	);

	if (epg.length === 0)
		return <span>We could not display any items on the timeline.</span>;
	return (
		<StepsTimeline
			initialPlannedStart={plannedStart}
			initialPlannedEnd={plannedEnd}
			epg={epg}
			channels={channels}
			CustomChannelItem={CustomChannelItem}
		/>
	);
};
