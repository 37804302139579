import { z } from "zod";
import { EMetadataType } from "./MetadataDefinition";

export const MetadataValue = z.object({
	createdBy: z.object({
		type: z.string(),
	}),
	value: z.union([z.string(), z.number(), z.array(z.string())]).optional(),
	id: z.string(),
});
export type MetadataValue = z.infer<typeof MetadataValue>;

export const Metadata = z.object({
	definition: z.object({
		id: z.string(),
		name: z.string(),
		type: EMetadataType,
		allowMany: z.boolean(),
		canBeEdited: z.boolean(),
		isFeatured: z.boolean(),
		isRequired: z.boolean(),
		enum: z.array(z.string()).optional(),
	}),
	values: z.array(MetadataValue),
});

export type Metadata = z.infer<typeof Metadata>;

export const ArrayOfMetadata = z.array(Metadata);
export type ArrayOfMetadata = z.infer<typeof ArrayOfMetadata>;
