import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { apiGet } from "./api";
import { useQuery } from "@tanstack/react-query";
import type { ProcessStreamResourceAllocations } from "@metronome/types/ProcessStreamResourceAllocations";

const resourceAllocationsKeys = {
	definitions: (
		workspaceId: string,
		organizationId: string,
		streamId: string,
		stepTemplateId?: string,
	) =>
		[
			workspaceId,
			organizationId,
			"resource-allocations",
			streamId,
			stepTemplateId,
		] as const,
};

export function useResourceAllocationsDefs(
	streamId: string,
	stepTemplateId?: string,
) {
	const workspaceId = useWorkspaceId();

	return useQuery({
		queryKey: resourceAllocationsKeys.definitions(
			workspaceId,
			"activeOrganization",
			streamId,
			stepTemplateId,
		),

		queryFn: () =>
			apiGet<ProcessStreamResourceAllocations[]>(
				`ws/${workspaceId}/process-streams/${streamId}/resource-allocations`,
				{
					params: {
						stepTemplateId,
					},
				},
			),
	});
}
