import { singleProcessStreamsOptions } from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { PageTitle } from "@metronome/context/PageData";
import { ProcessStreamTemplate } from "@metronome/features/ProcessStreamTemplate";
import { FormattedMessage, useIntl } from "react-intl";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import { ProcessTimelineLoader } from "./-timeline";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { UserProcessConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import type { ProcessContext } from "@metronome/types/ProcessInstance";
import useUserPreferences from "@metronome/api/useUserPreference";
import { useState } from "react";
import * as v from "valibot";
import { SelectResource } from "@metronome/features/SelectResource";
import { useStepInstancesFromPortfolio } from "@metronome/api/useStepInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { FilterDateRange } from "@metronome/components/FilterDateRange";
import { atEndOfDay, atMidnight } from "@metronome/utils/dateHelpers";
import { addDays } from "date-fns";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Button } from "@metronome/components/ui/button";
import { StepInstanceFromCluster } from "./-stepsTimelineGrouped";
import { useResourceAllocationsDefs } from "@metronome/api/useResourceAllocations";
import type { IProcessStream } from "@metronome/types/ProcessStream";

type StepInstancesByRessourceProps = {
	streamId: string;
	from: string;
	to?: string;
	stepTemplateId: string;
	nodeReferenceId: string;
};
const StepInstancesByRessource: React.FC<StepInstancesByRessourceProps> = ({
	streamId,
	from,
	to,
	stepTemplateId,
	nodeReferenceId,
}) => {
	const fromMidnight = atMidnight(from);
	const toEndOfDay = atEndOfDay(to);
	const { data, isLoading } = useStepInstancesFromPortfolio({
		streamId,
		from: fromMidnight ? fromMidnight?.toISOString().slice(0, 10) : "",
		to: toEndOfDay ? toEndOfDay?.toISOString().slice(0, 10) : "",
		stepTemplateId,
		nodeReferenceId,
	});
	if (isLoading) return <LoadingMetronome />;
	if (data) return <StepInstanceFromCluster clusteredStepInstances={data} />;
	return (
		<span>
			<FormattedMessage id="NO_STEPS_FOUND" />
		</span>
	);
};

type GroupedByRessourcesProps = {
	streamId: string;
};
const GroupedByRessources: React.FC<GroupedByRessourcesProps> = ({
	streamId,
}) => {
	const today = new Date();
	const in30days = addDays(today, 30);
	const [dateRange, setDateRange] = useState<[string, string] | undefined>([
		today.toISOString(),
		in30days.toISOString(),
	]);
	const { data: resources, isLoading: isResourceLoading } =
		useResourceAllocationsDefs(streamId);

	const { nodeReferenceId, stepTemplateId } = Route.useSearch();
	if (isResourceLoading) return <LoadingMetronome />;
	if (!resources || resources?.length === 0)
		return <span>no resources found</span>;
	return (
		<>
			<div className="flex items-center gap-2">
				<Dialog>
					<DialogTrigger className="m-1" asChild>
						<Button className="relative" variant="outline">
							<CalendarIcon className="me-1" />
							<FormattedMessage id="PLANNED_START" />
							{!!dateRange?.length && (
								<span className="absolute -top-2 -right-2 w-4 h-4 text-center text-xs shadow rounded-full bg-primary text-white">
									{"1"}
								</span>
							)}
						</Button>
					</DialogTrigger>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>
								<FormattedMessage id="FILTER_DATE_RANGE" />
							</DialogTitle>
						</DialogHeader>
						<FilterDateRange value={dateRange} setValue={setDateRange} />
					</DialogContent>
				</Dialog>
				<SelectResource
					resources={resources}
					nodeReferenceId={nodeReferenceId}
				/>
			</div>
			<div>
				{dateRange?.[0] && (
					<StepInstancesByRessource
						streamId={streamId}
						from={dateRange[0]}
						to={dateRange?.[1]}
						stepTemplateId={stepTemplateId ?? resources[0].stepTemplateId}
						nodeReferenceId={
							nodeReferenceId ?? resources[0].nodeReferenceDefinitionId
						}
					/>
				)}
			</div>
		</>
	);
};

const portfolioParams = v.object({
	nodeReferenceId: v.optional(v.string()),
	stepTemplateId: v.optional(v.string()),
});

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/portfolio",
)({
	validateSearch: (searchParams) => v.parse(portfolioParams, searchParams),
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const { streamId, workspaceId } = params;

		const promises: Array<Promise<unknown>> = [];

		promises.push(
			queryClient.ensureQueryData(
				singleProcessStreamsOptions(workspaceId, streamId),
			),
		);

		await Promise.all(promises);
		return;
	},
	component: () => {
		const { streamId, workspaceId } = Route.useParams();
		const { data: processStream } = useQuery(
			singleProcessStreamsOptions(workspaceId, streamId),
		);
		if (processStream) return <PortfolioPage processStream={processStream} />;
	},
});

const PortfolioPage = ({
	processStream,
}: { processStream: IProcessStream }) => {
	const [activeTab, setActiveTab] = useState("list");
	const { organizations } = useOrganizationData();
	const intl = useIntl();
	const organization = organizations?.find(
		(orga) => orga.id === processStream?.organization.id,
	);

	const state = "active"; // to refactor
	const PREFERENCE_KEY = `metronome.processStream.instances.active.${processStream.id}`;

	const { data: preferences } = useUserPreferences(PREFERENCE_KEY);

	return (
		<ProcessStreamTemplate processStream={processStream} isLoading={false}>
			<PageTitle organizationName={organization?.name}>
				{intl.formatMessage({ id: "MENU.PROCESS_STREAM" })}
			</PageTitle>
			<Tabs value={activeTab} onValueChange={setActiveTab}>
				<TabsList className="bg-white">
					<TabsTrigger className="font-bold" value="list">
						<FormattedMessage id="LIST" />
					</TabsTrigger>
					<TabsTrigger className="font-bold" value="timeline">
						<FormattedMessage id="TIMELINE" />
					</TabsTrigger>
				</TabsList>
				<TabsContent value="list">
					<ActiveViewProvider preferences={preferences}>
						<UserProcessConfigLoader
							context={state as ProcessContext}
							key={state}
							states={["active"]}
							times={["past", "today"]}
							processStreamId={processStream.id}
							preferenceKey={PREFERENCE_KEY}
							preferences={preferences}
						/>
					</ActiveViewProvider>
				</TabsContent>
				<TabsContent value="timeline">
					<ProcessTimelineLoader streamId={processStream.id} />
				</TabsContent>
			</Tabs>
		</ProcessStreamTemplate>
	);
};
